import { IconButton, Tr, useOutsideClick } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { MdCheck } from "react-icons/md";
import { CustomCheckbox } from "../checkbox/checkbox";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DisplayCellV2 } from "../cell/display-cell-v2";
import { Cell } from "../cell/cell";
import { DATAGRID_BODY_ROW_CELL_TESTID } from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { getSelectedRowBackground } from "../../utils/theme-utils";
import { getRowValues } from "../../utils/get-row-values";
import { EditCellV2 } from "../cell/edit-cell-v2";
import { getResultData } from "../../utils/row-utils";
import { useInlineEditContext } from "../../context/inline-edit-context";
import { checkChanges } from "../../utils/check-row-changes";
import {
  getBackground,
  getWhiteSpace,
  isChecked,
} from "../../utils/row-style-utils";

const filterRowKeys = (k, columns) =>
  columns.find((c) => c.name.toLowerCase() === k.toLowerCase());

const sortColumns = (a, b, columns) =>
  parseInt(
    columns.find((c) => c.name.toLowerCase() === a.toLowerCase()).order,
  ) -
  parseInt(columns.find((c) => c.name.toLowerCase() === b.toLowerCase()).order);

export const RowV2 = (props) => {
  const { row, style, onRowClick, onRowDoubleClick, onRowSelect, columns } =
    props;

  const {
    columns: defaultColumns,
    selectionMode,
    selectedItems,
    actions: { onRowEdit },
    enableEdit,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
    settings,
  } = useDatagridContext();

  const {
    getIsFocused,
    onCellClick,
    onKeyDown,
    exitEditMode,
    editMode,
    focusedRow,
    getIsEditable,
    onFocus,
  } = useInlineEditContext();

  const [rowState, setRowState] = useState(() => getRowValues(row));

  const handleRowClick = (e) => {
    if (editMode) return;
    if (!onRowClick) return;
    onRowClick();
  };

  const handleRowDoubleClick = (e) => {
    if (editMode) return;
    if (!onRowDoubleClick) return;
    onRowDoubleClick();
  };

  const handleRowEdit = useCallback(() => {
    if (!onRowEdit || !enableEdit) return;
    const isChanged = checkChanges(getRowValues(row), rowState);
    if (isChanged) onRowEdit(rowState);
  }, [rowState]);

  const rowRef = useRef();
  useOutsideClick({
    ref: rowRef,
    handler: () => {
      // if (editMode && enableEdit) {
      if (getIsEditable(row)) {
        handleRowEdit();
        exitEditMode();
      }
      // }
    },
  });

  const timeoutRef = useRef(null);
  const handleCellValue = useCallback(
    async (value, column) => {
      const { name } = column;
      setRowState((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (column.onChangeEvent && rowState[name] !== value) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(async () => {
          const { onChangeEvent } = column;
          const state = { ...rowState, [name]: value };
          const res = await onChangeEvent(state);
          const result = getResultData(res);
          setRowState((prev) => ({ ...prev, ...result }));
        }, 1000);
      }
    },
    [rowState],
  );

  const handleKeyDown = useCallback(
    async (e) => {
      if (e.key === "Enter") {
        handleRowEdit();
      }
      onKeyDown(e);
    },
    [handleRowEdit],
  );

  return (
    <Tr
      ref={rowRef}
      onClick={handleRowClick}
      onDoubleClick={handleRowDoubleClick}
      style={{
        ...style,
        borderBottom: "1px solid black",
        background: getBackground(row, selectedItems, style),
      }}
    >
      {(selectionMode === "single" || selectionMode === "multiple") && (
        <Cell
          column={defaultColumns.find((col) => col.name === "checkbox")}
          width={defaultColumns.find((col) => col.name === "checkbox").width}
          key={"checkbox-body"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px 8px",
          }}
          data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        >
          {getIsEditable(row) ? (
            <IconButton
              icon={<MdCheck />}
              onClick={handleRowEdit}
              variant="outline"
            />
          ) : (
            <CustomCheckbox
              isChecked={isChecked(row, selectedItems)}
              onChange={onRowSelect}
              value={getId(row)?.value}
            />
          )}
        </Cell>
      )}
      {Object.keys(row)
        .filter((k) => filterRowKeys(k, columns))
        .sort((a, b) => sortColumns(a, b, columns))
        .map((k) => {
          const column = columns.find(
            (c) => c.name.toLowerCase() === k.toLowerCase(),
          );

          const editable = getIsEditable(row) && column.editable;

          return !editable ? (
            <DisplayCellV2
              data={row[k]}
              column={column}
              value={rowState[k]}
              row={rowState}
              onClick={(cell) =>
                onCellClick({
                  ...cell,
                  order: column.order,
                  colName: column.name,
                  row: getId(row).value,
                })
              }
              onChange={
                row[k].editable
                  ? async (value) => {
                      await handleCellValue(value, column);
                      onRowEdit({ ...rowState, [k]: value });
                    }
                  : () => {}
              }
              style={{
                whiteSpace: getWhiteSpace(
                  settings?.lockRowHeight,
                  cellWhiteSpace,
                ),
                fontSize: rowFontSize,
                padding: "0 5px",
              }}
              key={`${k}-${getId(row)?.value}`}
            />
          ) : (
            <EditCellV2
              column={column}
              data={row[k]}
              value={rowState[k]}
              row={rowState}
              onChange={handleCellValue}
              autoFocus={getIsFocused(row, column)}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
              key={`${column.name}-${getId(row)?.value}-${k.toString()}`}
              data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
              style={{ whiteSpace: cellWhiteSpace }}
            />
          );
        })}
    </Tr>
  );
};
