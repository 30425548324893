import { useCallback, useEffect, useRef, useState } from "react";
import {
  addArrayElementAction,
  addValueAction,
  updateManyValueInArrayObjectAction,
  updateValueAction,
  updateValueInArrayObjectAction,
} from "../../../../../../../services/settings-service";
import { getColumnsFromData } from "../utils/get-columns-from-data";
import { removeColumnProp } from "../utils/remove-column-prop";
import { getChangesForColumns } from "../utils/get-changes-for-columns";

const getUserId = (userId, stateUserId) => {
  if (userId === "all") return null;

  return userId ? parseInt(userId) : parseInt(stateUserId);
};

const getCompanyId = (companyId, stateCompanyId) => {
  if (companyId === "all") return null;

  return companyId ? parseInt(companyId) : parseInt(stateCompanyId);
};

export const useColumns = (initialColumns, settingData, onColumnChange) => {
  const [columns, setColumns] = useState(initialColumns || []);
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (initialRenderRef.current) return;
    if (initialColumns) {
      setColumns(initialColumns);
    }
  }, [initialColumns]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }
    onColumnChange(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const updateUserColumns = useCallback(
    (columns, userId, companyId) => {
      const newColumns = columns
        .map(removeColumnProp)
        .filter((c) => c.name.toLowerCase() !== "checkbox");

      const payload = {
        ...settingData,
        userId: getUserId(userId, settingData.userId),
        companyId: getCompanyId(companyId, settingData.companyId),
        name: "columns",
        value: newColumns,
      };

      const key = "use-settings-update-value-columns";

      updateValueAction(payload, key).catch(async (e) => {
        if (e.status === 404 && e.statusText === "Not Found") {
          const key = "use-settings-add-value-columns";
          await addValueAction(payload, key).catch(() => {});
        }
      });
    },
    [settingData],
  );

  const updateColumn = useCallback(
    async (name, prop, value, dataColumns) => {
      if (columns.length === 0) {
        const newColumns = getColumnsFromData(dataColumns)
          .map(removeColumnProp)
          .map((c) => {
            if (c.name === name) return { ...c, [prop]: value };
            return c;
          })
          .filter((c) => c.name.toLowerCase() !== "checkbox");

        const key = "use-settings-add-value-columns";
        const payload = {
          ...settingData,
          name: "columns",
          value: newColumns,
        };

        await addValueAction(payload, key).catch(() => {});
        setColumns(newColumns);

        return;
      }

      const key = "use-settings-update-value-in-array-obj-columns";
      const payload = {
        ...settingData,
        arrayName: "columns",
        locatorKey: "name",
        locatorValue: name,
        propertyName: prop,
        value,
      };

      await updateValueInArrayObjectAction(payload, key)
        .then((res) => {
          const value = JSON.parse(res.value);
          const columns = value.columns;
          const newColumns = columns
            .map(removeColumnProp)
            .filter((c) => c.name.toLowerCase() !== "checkbox");
          setColumns(newColumns);
        })
        .catch(() => {
          const payload = {
            ...settingData,
            arrayName: "columns",
            locatorKey: "name",
            locatorValue: name,
            value: { name, [prop]: value },
          };

          addArrayElementAction(payload, key)
            .then((res) => {
              const value = JSON.parse(res.value);
              const columns = value.columns;
              const newColumns = columns
                .map(removeColumnProp)
                .filter((c) => c.name.toLowerCase() !== "checkbox");
              setColumns(newColumns);
            })
            .catch(() => {});
        });
    },
    [columns.length, settingData],
  );

  const updateManyColumns = useCallback(
    async (newColumns, userId, companyId) => {
      const mappedNewColumns = newColumns
        .map(removeColumnProp)
        .filter((c) => c.name.toLowerCase() !== "checkbox");

      if (columns.length === 0) {
        const key = "use-settings-add-value-columns";
        const payload = {
          ...settingData,
          userId: getUserId(userId, settingData.userId),
          companyId: getCompanyId(companyId, settingData.companyId),
          name: "columns",
          value: mappedNewColumns,
        };

        await addValueAction(payload, key).catch(() => {});
        setColumns(mappedNewColumns);

        return;
      }

      const withoutCheckbox = columns.filter((c) => c.name !== "checkbox");

      const changes = getChangesForColumns(
        withoutCheckbox.map(removeColumnProp),
        mappedNewColumns,
      );

      const key = "use-settings-batch-update-value-in-array-obj-columns";
      const payload = {
        ...settingData,
        userId: getUserId(userId, settingData.userId),
        companyId: getCompanyId(companyId, settingData.companyId),
        batch: changes.map((c) => ({
          arrayName: "columns",
          locatorKey: "name",
          locatorValue: c.name,
          propertyName: c.propName,
          value: c.propValue,
        })),
      };

      await updateManyValueInArrayObjectAction(payload, key).catch(
        async (e) => {
          if (e.status === 404 && e.statusText === "Not Found") {
            const key = "use-settings-add-value-columns";
            const payload = {
              ...settingData,
              userId: getUserId(userId, settingData.userId),
              companyId: getCompanyId(companyId, settingData.companyId),
              name: "columns",
              value: mappedNewColumns,
            };

            await addValueAction(payload, key).catch(() => {});
          }
        },
      );

      setColumns(mappedNewColumns);
    },
    [columns, settingData],
  );

  return {
    columns,
    updateManyColumns,
    updateColumn,
    updateUserColumns,
  };
};
