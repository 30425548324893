import { getId } from "./get-id";
import { getSelectedRowBackground } from "./theme-utils";

export const getWhiteSpace = (lock) => (lock === false ? "normal" : "nowrap");

export const isChecked = (record, selectedItems) =>
  selectedItems?.find((item) => item == getId(record)?.value) ? true : false;

export const getBackground = (record, selectedItems, style) => {
  const checked = isChecked(record, selectedItems);
  return getSelectedRowBackground(checked, style?.background);
};
