import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { Input } from "@echo/ui";
import { useMemo } from "react";
import { useComponentRegister } from "../../../../../../components-registry/hooks/use-component-register";

const getBoolValue = (val) => {
  if (val === true || val === false) return val;
  if (val == "1" || val == "true") return true;

  return false;
};

const InputComponent = ({
  name,
  value,
  onChange,
  onFocusLost,
  rootProps,
  onDoubleClick,
  placeholder,
  variant,
  type,
  label = "",
  id,
  colorScheme,
  isDisabled,
  isInvalid,
  isReadOnly,
  isRequired,
  context,
  size,
  regExp,
  tabOrder,
  autoComplete,
  maxLength,
}) => {
  const [inputValue, setInputValue] = useDebouncedValueState(
    value,
    onChange,
    name,
  );

  const isReadOnlyState = useResolveProp(isReadOnly, true);
  const isRequiredState = useResolveProp(isRequired, true);

  const handleOnInputChange = (e) => {
    if (!regExp) {
      setInputValue(e.target.value);
    } else {
      if (new RegExp(regExp).test(e.target.value)) {
        setInputValue(e.target.value ? e.target.value : null);
      } else {
        window.console.error("RegEx Error", regExp);
      }
    }
  };

  const handleBlur = (e) => {
    const value = e.target.value;
    if (onFocusLost) onFocusLost(value);
  };

  const { nodeRef, onClick, ...restRootProps } = rootProps;

  const component = useMemo(
    () => ({
      name,
      getData: () => [{ name, value: inputValue }],
    }),
    [name, inputValue],
  );

  useComponentRegister(context, component);

  return (
    <Input
      nodeRef={nodeRef}
      {...restRootProps}
      {...{
        variant,
        type,
        label,
        id: id.toString(),
        colorScheme,
        isDisabled,
        isInvalid,
        isReadOnly: isReadOnlyState,
        isRequired: getBoolValue(isRequiredState),
        size,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      onDoubleClick={onDoubleClick}
      value={inputValue ?? ""}
      onChange={handleOnInputChange}
      placeholder={placeholder ?? " "}
      tabIndex={parseFloat(tabOrder)}
      autoFocus={parseFloat(tabOrder) == 1}
      onBlur={handleBlur}
      autoComplete={autoComplete === false ? "off" : "on"}
      maxLength={maxLength}
    />
  );
};

InputComponent.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onFocusLost: PropTypes.func,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.bool,
  tabOrder: PropTypes.string,
  type: PropTypes.oneOf([undefined, "password", "number", "text"]),
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  style: PropTypes.object,
  nodeRef: PropTypes.any,
  colorScheme: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isUnique: PropTypes.bool,
  maxLength: PropTypes.number,
  size: PropTypes.string,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  rootProps: PropTypes.any,
  regExp: PropTypes.string,
  context: PropTypes.object,
};

export default withBaseComponent(InputComponent);
