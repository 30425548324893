import React, { useEffect, useRef, useState } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useDatagridContext } from "../../../hooks/useDatagridContext";
import { useTimeoutValue } from "../../../hooks/use-timeout-value";

export const InputEditCell = (props) => {
  const { value, onChange, onFocus, autoFocus, onKeyDown } = props;
  const { settings } = useDatagridContext();

  const inputRef = useRef();

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return settings?.lockRowHeight === false ? (
    <Textarea
      ref={inputRef}
      value={value || ""}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      width="100%"
      fontSize="12px"
      padding="2px 4px"
    />
  ) : (
    <Input
      ref={inputRef}
      value={value || ""}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      autoFocus={autoFocus}
      width="100%"
    />
  );
};

InputEditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};
