import { useEffect, useState } from "react";

export const useTreeView = (dataSource) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(dataSource);
  }, [dataSource]);

  const onChange = (...params) => {
    const sql = params[0];
    const groupId = params[2].split("_")[1];
    const queryId = params[2].split("_")[3];

    const groups = params[4];
    const groupItem = groups?.[groups?.length - 1]?.path;
    const splitted = groupItem?.split("_");
    const groupItemId = splitted?.[splitted?.length - 1];

    setValue((prev) => ({
      ...prev,
      staticParams: {
        sql: sql || groups?.[0]?.value,
        query: sql || groups?.[0]?.value,
        queryId: parseInt(queryId),
        groupId: parseInt(groupId),
      },
      queryParams: [
        {
          name: "groupItemId",
          value: groupItemId ? parseInt(groupItemId) : null,
        },
      ],
    }));
  };

  return { onChange, value };
};
