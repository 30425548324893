import { echoPortal } from "../../components/echo-portal/echo-portal";
import QuestionAlert from "../../components/shared/echo-components/base-components/components/question-alert-component/question-alert.component";

export const alertBlock = (block) => {
  return {
    definition: block,
    execute: async (staticParams, params) => {
      const parsedBody = staticParams?.__processBlock?.body
        ? JSON.parse(staticParams.__processBlock.body)
        : {};

      const mergedParams = {
        ...(staticParams ?? {}),
        ...(params[0] ?? {}),
        ...parsedBody,
      };

      const getChoice = () =>
        new Promise((resolve) =>
          echoPortal.render((container) => (
            <QuestionAlert
              open={true}
              header={mergedParams.header}
              question={mergedParams.question}
              alertMode={mergedParams.alertMode ?? "Yes/No"}
              onAction={(type) => {
                container.unmount();
                resolve({ __resultCondition: type });
              }}
            />
          )),
        );

      const res = await getChoice();
      return res;
    },
    getMetadata: () => {},
  };
};
