import React, { useCallback, useMemo, useRef, useState } from "react";
import { Table, TableContainer, Box } from "@chakra-ui/react";
import { Header } from "./header/header";
import { Footer } from "./footer/footer";
import { Navigation } from "./navigation/navigation";
import { Body } from "./body/body";
import { useDatagridContext } from "../hooks/useDatagridContext";
import { FilterRow } from "./header/filter-row";
import { DATAGRID_CONTAINER_TESTID } from "../utils/test-ids";
import { useDragContext } from "../hooks/useDragContext";
import { mapColumnOrder } from "./cell/header-cell/utils";
import {
  DragContextProvider,
  DragContainer,
  DragSection,
} from "@echo/draggable";
import { BodyV2 } from "./body/body-v2";

export const DatagridSimple = () => {
  const {
    data,
    loading,
    disableFilters,
    settings,
    disableHeader,
    disableFooter,
    disableSummary,
    visibleColumns,
    navPosition,
    summaryValues,
  } = useDatagridContext();

  const tableRef = useRef();

  const getWidth = useCallback(
    (column, arr) => {
      if (column.width) return column.width;
      if (!tableRef.current) return "150px";
      const width = tableRef.current.clientWidth;
      const columnsCount = arr.length;
      const resultWidth = Math.floor(width / columnsCount);
      if (resultWidth < 150) return "150px";
      return `${resultWidth}px`;
    },
    [tableRef],
  );

  const columns = useMemo(
    () =>
      visibleColumns.map((c, idx, arr) =>
        c.name === "checkbox" ? c : { ...c, width: getWidth(c, arr) },
      ),
    [visibleColumns],
  );

  return (
    <DragContextProvider>
      <DragContainer context="datagrid">
        <Box
          overflow="hidden"
          height="100%"
          width="100%"
          maxWidth="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          data-testid={DATAGRID_CONTAINER_TESTID}
        >
          {!disableFooter && navPosition === "top" && <Navigation />}
          <TableContainer
            height="100%"
            width="100%"
            border="1px solid var(--chakra-colors-gray-200)"
          >
            <Table
              overflow="auto"
              size="sm"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              height="100%"
              variant="unstyled"
              width="100%"
              position="relative"
              zIndex={2}
              ref={tableRef}
            >
              {!disableHeader && <Header columns={columns} />}
              <BodyV2 columns={columns} />

              {!disableSummary && data.length > 0 && (
                <Footer columns={columns} />
              )}
            </Table>
          </TableContainer>
          {!disableFooter && navPosition !== "top" && <Navigation />}
        </Box>
      </DragContainer>
    </DragContextProvider>
  );
};
