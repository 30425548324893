import { asyncArrayGet } from "./base-fetch";
import { baseDelete, baseGet } from "./base-fetch/base-fetch";
import getToken from "./token-service";
import { getApiUrl } from "./utils/api-uri";

export const deleteComponents = (ids) =>
  baseDelete("formComponents/DeleteComponents", { ids });

export const getComponent = async ({ id, name }) => {
  const responseObject = await baseGet("formComponents/GetComponent", {
    id,
    name,
  });
  return parseComponentJsonProps(responseObject);
};

export const getBaseComponentsList = async (
  componentPurpose,
  showBuiltIn = false,
) =>
  baseGet("formComponents/GetBaseComponentsList", {
    componentPurpose: mapComponentPurpose(componentPurpose),
    showBuiltIn,
  });

export const getComponentProperties = async ({ id, name }) =>
  await baseGet("formComponents/GetComponentProperties", {
    id,
    name,
  });

export const getComponentList = (
  componentPurpose,
  asyncChunkCallback,
  showBuiltIn = false,
  loadDependencyObjects = false,
) =>
  asyncChunkCallback
    ? new Promise((resolve, reject) => {
        asyncArrayGet(
          "formComponents/GetComponentList",
          {
            componentPurpose: mapComponentPurpose(componentPurpose),
            showBuiltIn,
            loadDependencyObjects,
          },
          (partialData) =>
            asyncChunkCallback(partialData.map(parseComponentJsonProps)),
        )
          .then((result) => resolve(result.map(parseComponentJsonProps)))
          .catch(reject);
      })
    : new Promise((resolve, reject) => {
        baseGet("formComponents/GetComponentList", {
          componentPurpose: mapComponentPurpose(componentPurpose),
          showBuiltIn,
          loadDependencyObjects,
        })
          .then((result) => resolve(result.map(parseComponentJsonProps)))
          .catch(reject);
      });

export const saveComponent = (component) =>
  new Promise((resolve, reject) => {
    fetch(getApiUrl() + "formComponents/SaveComponent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(stringifyComponentJsonProps(component)),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });

const stringifyChildrenJsonProps = (childrenComponents) => {
  const resultList = [];
  for (let index = 0; index < childrenComponents.length; index++) {
    const stringified = {
      ...childrenComponents[index],
      id:
        childrenComponents[index].id > 0
          ? childrenComponents[index].id
          : undefined,
    };
    stringified.componentProps = JSON.stringify(stringified.componentProps);
    stringified.component = stringifyComponentJsonProps(stringified.component);
    stringified.styleOptions = JSON.stringify(stringified.styleOptions);
    stringified.childrenComponents = stringifyChildrenJsonProps(
      stringified.childrenComponents,
    );
    resultList.push(stringified);
  }
  return resultList;
};

const stringifyComponentJsonProps = (component) => {
  const result = {
    ...component,
    id: component.id > 0 ? component.id : undefined,
  };
  if (result.crudCreate) {
    result.crudCreate = JSON.stringify(result.crudCreate);
  }
  if (result.crudRead) {
    result.crudRead = JSON.stringify(result.crudRead);
  }
  if (result.crudUpdate) {
    result.crudUpdate = JSON.stringify(result.crudUpdate);
  }
  if (result.crudDelete) {
    result.crudDelete = JSON.stringify(result.crudDelete);
  }
  if (result.crudActions) {
    result.crudActions = JSON.stringify(result.crudActions);
  }
  if (result.properties) {
    result.properties = JSON.stringify(result.properties);
  }
  result.styleOptions = JSON.stringify(result.styleOptions) ?? "{}";
  result.componentProps = JSON.stringify(result.componentProps) ?? "{}";
  result.availableStyleOptions =
    JSON.stringify(result.availableStyleOptions) ?? "[]";
  result.childrenComponents = stringifyChildrenJsonProps(
    result.childrenComponents,
  );
  return result;
};

const parseChildrenJsonProps = (childrenComponents) => {
  const resultList = [];
  for (let index = 0; index < childrenComponents.length; index++) {
    const parsed = {
      ...childrenComponents[index],
    };
    parsed.componentProps = JSON.parse(parsed.componentProps);
    parsed.component = parseComponentJsonProps(parsed.component);
    parsed.styleOptions = JSON.parse(parsed.styleOptions);
    parsed.childrenComponents = parseChildrenJsonProps(
      parsed.childrenComponents,
    );
    resultList.push(parsed);
  }
  return resultList;
};

const parseComponentJsonProps = (component) => {
  const result = {
    ...component,
  };
  if (result.crudCreate) {
    result.crudCreate = JSON.parse(result.crudCreate);
  }
  if (result.crudRead) {
    result.crudRead = JSON.parse(result.crudRead);
  }
  if (result.crudUpdate) {
    result.crudUpdate = JSON.parse(result.crudUpdate);
  }
  if (result.crudDelete) {
    result.crudDelete = JSON.parse(result.crudDelete);
  }
  if (result.crudActions) {
    result.crudActions = JSON.parse(result.crudActions);
  }
  if (result.properties) {
    result.properties = JSON.parse(result.properties);
  }
  result.styleOptions = JSON.parse(result.styleOptions) ?? {};
  result.componentProps = JSON.parse(result.componentProps) ?? {};
  result.availableStyleOptions = JSON.parse(result.availableStyleOptions) ?? [];
  result.childrenComponents = parseChildrenJsonProps(result.childrenComponents);
  return result;
};

const mapComponentPurpose = (componentPurpose) => {
  switch (componentPurpose) {
    case "Component":
      return 0;
    case "Form":
      return 1;
    case null:
    default:
      return null;
  }
};
