import { useCallback, useEffect, useState } from "react";
import { uuidv4 } from "@echo/tools";
import { toast } from "react-toastify";
import { getComponent } from "../../../../../utils/echo-component/echo-component-utils";
import { getPropertyDefinitionTypes } from "../../../../../services/component-property-definition-service";

const getPropertyTypes = async () => {
  const res = await getPropertyDefinitionTypes();
  return res.map((el) => ({ value: el, label: el }));
};

const getColumns = (changeFn) => [
  { name: "name", order: 0, type: "string", editable: true, required: true },
  {
    name: "type",
    order: 1,
    type: "combo",
    editable: true,
    required: true,
    editDataSource: async () => await getPropertyTypes(),
  },
  {
    name: "order",
    order: 2,
    type: "number",
    format: "##0",
    editable: true,
    required: false,
  },
  {
    name: "isSystem",
    order: 3,
    type: "boolean",
    editable: true,
    required: false,
    onChangeEvent: (row) => {
      changeFn(row);
      return row;
    },
  },
  {
    name: "options",
    order: 4,
    type: "string",
    editable: (row) => {
      if (row.type === "oneOf") return true;
      return false;
    },
    required: false,
  },
];

const getPropType = (type) => {
  if (type === "func") return "function";
  if (type === "bool") return "boolean";
  return type;
};

const getInitialSchema = (initialSchemaObject) => {
  if (!initialSchemaObject) return [];
  return initialSchemaObject.map((el) => {
    if (el.propName)
      return { id: el.id, name: el.propName, type: getPropType(el.propType) };
    return el;
  });
};

const getPropertyType = (property) => {
  const type = property.info.propTypeName;

  if (type === "func") return "function";
  if (type === "bool") return "boolean";

  return type;
};

const mapPropTypes = (propTypes, componentId) => {
  const keys = Object.keys(propTypes);

  return keys.map((k) => ({
    id: uuidv4(),
    componentId,
    name: k,
    type: getPropertyType(propTypes[k]),
  }));
};

const getPropertiesForComponent = (component) => {
  if (component.source === "!?echo-defined") return [];
  const propTypes = getComponent(component.source).propTypes;
  return mapPropTypes(propTypes, component.id);
};

export const usePropertiesTable = (component, initialSchemaObject) => {
  const [schemaObject, setSchemaObject] = useState([]);

  const initSchemaObject = (initialSchemaObject, component) => {
    const initialSchema = getInitialSchema(initialSchemaObject);

    const properties = getPropertiesForComponent(component);

    const order = Math.max(
      ...initialSchema.map((p) => (p.order ? p.order : null)).filter((p) => p),
    );

    const schemaObject = [
      ...initialSchema,
      ...properties
        .filter((p) => !initialSchema.find((el) => el.name === p.name))
        .map((p, idx) => ({ ...p, order: order + idx + 1 })),
    ];

    setSchemaObject(schemaObject);
  };

  useEffect(() => {
    initSchemaObject(initialSchemaObject, component);
  }, [initialSchemaObject, component]);

  const [selected, setSelected] = useState([]);
  const [enableAdding, setEnableAdding] = useState(false);

  const toggleAdding = () => setEnableAdding((prev) => !prev);

  const onRowSelect = (id, type) => {
    if (type === "select") setSelected((prev) => [...prev, id.toString()]);
    else
      setSelected((prev) =>
        prev.filter((el) => el.toString() !== id.toString()),
      );
  };

  const onSelectAll = (ids, type) => {
    if (type === "select") setSelected(ids.map((id) => id.toString()));
    else setSelected([]);
  };

  const onRowEdit = (row) => {
    setSchemaObject((prev) => prev.map((el) => (el.id === row.id ? row : el)));
  };

  const onRowAdd = (row) => {
    const emptyFields = Object.keys(row).filter(
      (k) => !row[k] || row[k] === "",
    );
    if (row.required && emptyFields.length > 0) {
      toast.error(
        `${emptyFields.join(", ")} ${emptyFields.length > 1 ? "are" : "is"} required.`,
      );
      return;
    }
    setSchemaObject((prev) => [...prev, { ...row, id: uuidv4() }]);
  };

  const deleteSelected = useCallback(() => {
    setSchemaObject((prev) =>
      prev.filter((el) => !selected.includes(el.id.toString())),
    );
  }, [selected, setSchemaObject]);

  const actions = {
    onRowClick: () => {},
    onRowDoubleClick: () => {},
    onFilterChange: () => {},
    onItemsPerPageChange: () => {},
    onPageChange: () => {},
    onSortChange: () => {},
    onColumnWidthChange: () => {},
    onColumnOrderChange: () => {},
    onColumnVisibilityChange: () => {},
    onColumnsSettingsChange: () => {},
    onRowSelect,
    onSelectAll,
    onRowEdit,
    onRowAdd,
    resetAction: () => {},
  };

  const reset = useCallback(() => {
    initSchemaObject(initialSchemaObject, component);
    setSelected([]);
    setEnableAdding(false);
  }, [component, initialSchemaObject]);

  return {
    data: schemaObject,
    columns: getColumns(onRowEdit),
    enableAdding,
    toggleAdding,
    selected,
    deleteSelected,
    actions,
    reset,
  };
};
