export const mergeUniqueObjects = (...arrays) => {
  const mergedObjects = {};

  const mergeObject = (obj) => {
    if (!mergedObjects[obj.name]) {
      mergedObjects[obj.name] = { ...obj };
    } else {
      mergedObjects[obj.name] = { ...mergedObjects[obj.name], ...obj };
    }
  };

  arrays.forEach((arr) => {
    arr.forEach(mergeObject);
  });

  return { object: mergedObjects, list: Object.values(mergedObjects) };
};

export const mergeArrays = (arr1, ...arrays) => {
  return arr1.map((i) => {
    const list = mergeUniqueObjects(...arrays).list;
    const f = list.find((f) => f.name === i.name);
    if (f) return { ...i, ...f };
    return i;
  });
};
