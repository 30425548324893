import PropTypes from "prop-types";
import "../../styles/main-component/user-menu.css";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  HStack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiUser } from "react-icons/fi";
import UserSelectListModal from "../user/user-change-dialog";
import { useHistory } from "../echo-router/hooks";
import ChangeUserContextModal from "./change-user-context-modal";
import { toast } from "react-toastify";
import { GET } from "../../services/base-fetch";
import { ChangeThemeModal } from "./change-theme-modal";
import { getApiUrl } from "../../services/utils/api-uri";

const ping = async () => {
  try {
    await toast.promise(GET(getApiUrl() + "EchoVersion/getEchoVersion"), {
      pending: "Sprawdzanie połączenia.",
      error: "Występują problemy w połączeniu z serwerem.",
      success: "Połączenie z serwerem jest stabilne.",
    });
  } catch (e) {
    console.error(e);
  }
};

const UserHeaderComponent = ({ userName, isSuperuser }) => {
  const history = useHistory();
  const changePasswordModal = useDisclosure();
  const changeContextModal = useDisclosure();
  const changeThemeModal = useDisclosure();

  const menuItems = [
    {
      text: "Strona główna",
      onClick: () => {
        history.push("/");
      },
    },
    {
      text: "Zmień hasło",
      onClick: changePasswordModal?.onOpen,
    },
    {
      text: "Sprawdź połączenie z serwerem",
      onClick: ping,
    },
    {
      text: "Zmień motyw",
      onClick: changeThemeModal?.onOpen,
    },
    {
      text: "Change user context",
      onClick: changeContextModal?.onOpen,
      permissions: ["admin"],
    },
  ];

  return (
    <Box>
      <HStack>
        <Menu>
          <MenuButton
            variant="outline"
            as={IconButton}
            aria-label="Options"
            icon={<FiUser color="white" />}
            color="white"
            borderColor="white"
          />
          <MenuList zIndex={999}>
            {menuItems.map((i, idx) => {
              if (i.permissions?.includes("admin") && !isSuperuser) return null;
              return (
                <MenuItem onClick={i.onClick} key={idx}>
                  {i.text}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
        <Box
          color="white"
          fontWeight="normal"
          maxWidth="30vw"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {userName}
        </Box>
      </HStack>
      <UserSelectListModal {...changePasswordModal} />
      <ChangeUserContextModal {...changeContextModal} />
      <ChangeThemeModal {...changeThemeModal} />
    </Box>
  );
};

UserHeaderComponent.propTypes = {
  userName: PropTypes.string.isRequired,
  isSuperuser: PropTypes.bool.isRequired,
};

export default UserHeaderComponent;
