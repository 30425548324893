import PropTypes from "prop-types";
import { Box, Text, Tooltip } from "@chakra-ui/react";

import { compareAsc, format } from "date-fns";
import { useCalendarDragContext } from "../hooks/use-calendar-drag-context";
import { useMemo } from "react";
import { EventResizer } from "../event-resizer/event-resizer";
import {
  getEventDay,
  getEventEnd,
  getEventMargin,
  getEventStart,
  getEventWidth,
  getEventZIndex,
} from "../utils/calendar-events-utils";
import { getFontColor } from "../utils/calendar-utils";

export const EventsList = ({
  events,
  onEventClick,
  onEventMove,
  availableCalendars,
  shift,
}) => {
  return events
    .filter((ev) => compareAsc(ev.from, ev.to) !== 0)
    .sort((a, b) => {
      const aStart = getEventStart(a, shift);
      const aEnd = getEventEnd(a, shift);
      const bStart = getEventStart(b, shift);
      const bEnd = getEventEnd(b, shift);
      const aDiff = aEnd - aStart;
      const bDiff = bEnd - bStart;

      return bDiff - aDiff;
    })
    .map((ev, _, arr) => (
      <Event
        eventsList={arr}
        event={ev}
        background={
          ev.color ||
          availableCalendars.find((c) => c.id === ev.calendarId)?.background
            ?.value
        }
        onClick={onEventClick}
        onMove={onEventMove}
        shift={shift}
        key={ev.id}
      />
    ));
};

EventsList.propTypes = {
  events: PropTypes.array,
  onEventClick: PropTypes.func,
  onEventMove: PropTypes.func,
};

export const Event = ({
  event,
  eventsList,
  onClick,
  background,
  zIndex,
  shift,
  dragged,
}) => {
  const { draggedElement, handleDragStart } = useCalendarDragContext();

  const column = useMemo(() => getEventDay(event), [event]);
  const start = useMemo(() => getEventStart(event, shift), [event, shift]);
  const end = useMemo(() => getEventEnd(event, shift), [event, shift]);

  let timeout;

  const margins = useMemo(
    () =>
      getEventMargin(
        eventsList,
        {
          id: event.id,
          date: event.from,
          column,
          start,
          end,
        },
        shift,
        dragged,
      ),
    [column, dragged, end, event.from, event.id, eventsList, shift, start],
  );

  const width = useMemo(
    () =>
      getEventWidth(
        eventsList,
        { id: event.id, date: event.from, column, start, end },
        shift,
        dragged,
      ),
    [eventsList, event.id, event.from, column, start, end, shift, dragged],
  );

  const calculatedZIndex = useMemo(
    () => getEventZIndex({ id: event.id, column, start, end }),
    [event, column, start, end],
  );

  const diff = useMemo(() => end - start, [end, start]);

  const hasOneLine = useMemo(() => {
    if (diff <= 3) return true;
    return false;
  }, [diff]);

  return (
    <Box
      width={zIndex ? "100%" : `${width}%`}
      padding="0 2px"
      {...margins}
      marginBottom="1px"
      gridRowStart={start}
      gridRowEnd={end}
      gridColumnStart={column}
      display="flex"
      alignItems="center"
      justifyContent="center"
      pointerEvents={draggedElement ? "none" : ""}
      position="relative"
      zIndex={zIndex ? zIndex : calculatedZIndex}
      onMouseOver={(e) => e.stopPropagation()}
    >
      <EventResizer event={event} position="top" />
      <Box
        boxShadow={`0 0 0 1px ${getFontColor(background)}`}
        height="100%"
        width="100%"
        borderRadius="12px"
        background={background}
        display="flex"
        flexDirection="column"
        padding={`${diff < 2 ? "0" : "2px"} 6px`}
        position="relative"
        cursor="pointer"
        gap="0"
        draggable
        justifyContent={hasOneLine ? "center" : "flex-start"}
        alignItems="flex-start"
        overflow="hidden"
        opacity={
          draggedElement && draggedElement.id === event.id && !zIndex
            ? "0.5"
            : "1"
        }
        pointerEvents={draggedElement ? "none" : ""}
        onDrag={(e) => {
          e.preventDefault();
        }}
        onDragStart={(e) => {
          e.preventDefault();
        }}
        onDragEnd={(e) => {
          e.preventDefault();
        }}
        onPointerDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          timeout = setTimeout(() => {
            handleDragStart(event, "drag");
          }, 300);
        }}
        onPointerUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
          clearTimeout(timeout);
          onClick({ ...event });
        }}
      >
        <Tooltip label={`${event.title}, ${format(event.from, "HH:mm")}`}>
          {hasOneLine ? (
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              color={getFontColor(background)}
              fontSize={`${diff < 2 ? ".65rem" : ".8rem"}`}
              lineHeight={`${diff < 2 ? ".6rem" : ".9rem"}`}
            >
              {event.title}, {format(event.from, "HH:mm")}
            </Text>
          ) : (
            <>
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                color={getFontColor(background)}
                fontSize=".8rem"
                lineHeight=".9rem"
              >
                {event.title}
              </Text>
              <Text
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                color={getFontColor(background)}
                fontSize=".8rem"
                lineHeight=".9rem"
              >
                {format(event.from, "HH:mm")}
              </Text>
            </>
          )}
        </Tooltip>
      </Box>
      <EventResizer event={event} position="bottom" />
    </Box>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    from: PropTypes.object,
    to: PropTypes.object,
  }),
  eventsList: PropTypes.array,
  background: PropTypes.shape({ value: PropTypes.string }),
  onClick: PropTypes.func,
  onMove: PropTypes.func,
  zIndex: PropTypes.string,
  shift: PropTypes.number,
  dragged: PropTypes.bool,
};
