import { Cell } from "./cell";
import { TypedEditCell } from "./edit-cell/typed-edit-cell";
import React, { useCallback } from "react";
import { TypedEditCellV2 } from "./edit-cell/typed-edit-cell-v2";
import { getId } from "../../utils/get-id";

export const EditCellV2 = (props) => {
  const {
    column,
    data,
    value,
    row,
    onChange,
    autoFocus,
    onFocus,
    style,
    onKeyDown,
  } = props;

  const handleChange = (value) => {
    if (value === "") onChange(null, column);
    else onChange(value, column);
  };

  const handleFocus = useCallback(() => {
    const rowId = getId(row);
    const colName = column.name;
    onFocus(rowId, colName);
  }, [column, row]);

  return (
    <Cell
      column={column}
      width={column.width}
      key={`${column.name}`}
      style={{ ...style, textAlign: column?.align }}
      onClick={() => {}}
    >
      <TypedEditCellV2
        data={data}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        column={column}
        autoFocus={autoFocus}
        onFocus={handleFocus}
        row={row}
      />
    </Cell>
  );
};
