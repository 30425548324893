import PropTypes from "prop-types";
import { Box, Text } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import FilePreviewer from "react-file-previewer-v2";
import { useEffect, useMemo, useState } from "react";
import getToken from "../../../../../../services/token-service";
import { MozillaPdf } from "@echo/ui/";
import { isPdf, checkType } from "@echo/tools";
import { getApiUrl } from "../../../../../../services/utils/api-uri";

const signatures = {
  JVBERi0: "application/pdf",
  R0lGODdh: "image/gif",
  R0lGODlh: "image/gif",
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg",
};

const detectMimeType = (b64) => {
  for (var s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s];
    }
  }
  return "application/pdf";
};

const getProxyUrl = (url) => {
  let fixedUri = url;
  if (!url.includes(getApiUrl())) {
    fixedUri =
      getApiUrl() + "File/proxyFileFromUrl?url=" + encodeURIComponent(url);
  }
  return fixedUri;
};

const getBlob = async (url) => {
  const token = getToken();

  if (token && url) {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return await response.blob();
  } else {
    return undefined;
  }
};

const getFileDescriptor = async (type, value) => {
  switch (type) {
    case "url": {
      const proxyUrl = getProxyUrl(value);
      const blob = await getBlob(proxyUrl);
      const isPdfBlob = await isPdf(blob);
      if (blob) {
        return {
          url: URL.createObjectURL(blob),
          mimeType: isPdfBlob ? "application/pdf" : blob.type,
        };
      } else {
        return { url: value };
      }
    }
    case "base64":
      return {
        data: value,
        mimeType: detectMimeType(value),
        name: "attachment",
      };
    default:
      return undefined;
  }
};

const FieViewerComponent = ({ rootProps, value, placeholder }) => {
  const { nodeRef, ...restRootProps } = rootProps;

  const [valueState, setValueState] = useState(null);
  const [fileDescriptor, setFileDescriptor] = useState();

  useEffect(() => {
    if (typeof value === "function") {
      Promise.resolve(value()).then((result) => {
        switch (typeof result) {
          case "object":
            if (Array.isArray(result)) {
              if (result.length > 0 && result[0]?.value) {
                setValueState(result[0].value);
              }
            } else if (result?.value) {
              setValueState(result.value);
            }
            break;
          default:
            setValueState(result);
        }
      });
    } else {
      setValueState(value);
    }
  }, [value]);

  const type = useMemo(() => checkType(valueState), [valueState]);

  useEffect(() => {
    let mounted = true;
    const loadDescriptor = async () => {
      const descriptor = await getFileDescriptor(type, valueState);
      if (mounted) {
        setFileDescriptor(descriptor);
      }
    };

    loadDescriptor();
    return () => {
      mounted = false;
    };
  }, [type, valueState]);

  const viewer = () => {
    if (fileDescriptor.mimeType === "application/pdf" && fileDescriptor.url) {
      return <MozillaPdf src={fileDescriptor.url} />;
    } else {
      return <FilePreviewer file={fileDescriptor} />;
    }
  };

  return (
    <Box position="relative" {...restRootProps} ref={nodeRef}>
      {!valueState || !fileDescriptor ? (
        <Box
          position="absolute"
          top="50%"
          left={0}
          fontSize="smaller"
          width="100%"
          backgroundColor="transparent"
          textAlign="center"
        >
          <Text>{placeholder || "Select attachment"}</Text>
        </Box>
      ) : type !== "unknown" && type ? (
        viewer()
      ) : (
        <Box>Incorrect value</Box>
      )}
    </Box>
  );
};

FieViewerComponent.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rootProps: PropTypes.object,
};

export default withBaseComponent(FieViewerComponent);
