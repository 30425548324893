// @flow
import React, { useMemo } from "react";
import { Checkbox } from "@chakra-ui/react";

export const BoolEditCell = (props: {
  value: string | number | boolean,
  onChange: () => void,
  autoFocus: boolean,
}) => {
  const { value, onChange, autoFocus, onKeyDown } = props;

  const getIsChecked = (value) => {
    if (value === "true" || value === true || value == "1") return true;

    return false;
  };

  const isChecked = useMemo(() => getIsChecked(value), [value]);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) onChange(1);
    else onChange(0);
  };

  return (
    <Checkbox
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
      isChecked={isChecked}
      onChange={handleChange}
    />
  );
};
