import EchoComponent from "../../../echo-component";
import PropTypes from "prop-types";
import { withEchoComponent } from "../../../../../../echo-component/function-wrapper/function-wrapper";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { Fragment, useMemo } from "react";

const FlexRendererComponent = ({
  id,
  guid,
  nodeId,
  childs,
  designerMode,
  path,
  localContext,
  context,
  nodeRef,
  style,
  isInvalid,
  state = {},
  stateDispatch = shimFunctions.shimFunction1,
  ...restRootProps
}) => {
  /** We need a global mode to determine if components should replace properties
   * mode - global mode
   * designerMode - local mode
   */
  const [, , mode] = useApiContext(id, guid, nodeId);

  const borderStyles = useMemo(() => {
    const { borderWidth, borderStyle, borderColor } = style;
    if (isInvalid)
      return {
        borderWidth: borderWidth ?? "1px",
        borderStyle: borderStyle ?? "solid",
        borderColor: "red",
      };

    return {
      borderWidth,
      borderStyle,
      borderColor,
    };
  }, [isInvalid, style]);

  return (
    <div
      {...restRootProps}
      ref={nodeRef}
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        position: "relative",
        ...{ ...style, ...borderStyles },
      }}
      data-value={mode === "designer" ? JSON.stringify({ id }) : undefined}
    >
      {childs.map((child) => (
        <Fragment key={child.key || `child-${child.id}-${context?.page?.key}}`}>
          {withEchoComponent(
            <EchoComponent
              context={context}
              source={child.component.source}
              styleOptions={{
                ...child.styleOptions,
              }}
              componentProps={{
                ...child.componentProps,
                isReadOnly:
                  child.componentProps.isReadOnly ||
                  restRootProps.isReadOnly ||
                  false,
              }}
              childs={child.childrenComponents}
              systemProps={{
                id: child.id,
                guid: child.guid,
                nodeId: child.nodeId,
                depth: child.depth,
                state,
                localContext,
                stateDispatch,
                onMoveElement: restRootProps.onMoveElement,
                dataSource: child.componentProps.dataSource,
                designerMode,
                component: child.component,
                childs: child.childrenComponents,
                context,
                path: path,
              }}
            />,
            mode,
          )}
        </Fragment>
      ))}
    </div>
  );
};

FlexRendererComponent.propTypes = {
  id: PropTypes.number,
  key: PropTypes.any,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  childs: PropTypes.array.isRequired,
  designerMode: PropTypes.bool,
  state: PropTypes.any,
  style: PropTypes.any,
  nodeRef: PropTypes.any,
  stateDispatch: PropTypes.funcOf(PropTypes.any),
  context: PropTypes.any,
  path: PropTypes.string.isRequired,
  rootProps: PropTypes.object,
  localContext: PropTypes.any,
  isInvalid: PropTypes.any,
};

export default FlexRendererComponent;
