import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDataReducer } from "../reducers/data-reducer";
import { fetchData, fetchDataByQuery } from "../utils/fetch-data";
import { showErrorToast } from "../../../../../echo-error-toast";
import { getAvailableObjects } from "../../../../../../../services/echo-object-service";
import { getCompanies } from "../../../../../../../services/company-service";
import { getQueryById } from "../../../../../../../services/query-service";
import { useDataLoading } from "./use-data-loading";
import { mergeArrays } from "../utils/merge-unique-objects";
import { removeColumnProp } from "../utils/remove-column-prop";
import { rowReducer } from "../utils/row-reducer";
import { getFrontendFilteredData } from "../utils/get-frontend-filtered-data";
import { getFrontendSortedData } from "../utils/get-frontend-sorted-data";
import { addSettingsToColumns } from "../utils/add-settings-to-columns";

const useDebounceCallback = (callback, delay) => {
  const timeoutRef = useRef(null);

  return useCallback(
    (...args) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => callback(...args), delay);
    },
    [callback, delay],
  );
};

const getIsVisible = (c) => {
  if (c.isVisible === true || c.isVisible === false) return c.isVisible;
  if (c.name.toLowerCase() === "id") return false;

  return true;
};

export const useData = (
  context,
  dataSource,
  settings,
  name,
  objectId,
  queryId,
  columns,
  environment,
) => {
  const { state, setState, setField } = useDataReducer();
  const { page } = state;
  const { sorting, itemsPerPage, filters } = settings;

  const getDataWithPromises = useCallback(
    (data, stateColumns) => {
      const list = mergeArrays(
        stateColumns,
        columns,
        settings.columns.map(removeColumnProp),
      );

      const newColumns = list
        .map((c) => ({ ...c, isVisible: getIsVisible(c) }))
        .sort((a, b) => parseInt(a.order) - parseInt(b.order))
        .map((c, idx) => ({ ...c, order: idx }))
        .sort((a, b) => parseInt(a.order) - parseInt(b.order));

      const promises = data.map(async (row) => {
        const keys = Object.keys(row);
        const entries = await Promise.all(
          keys.map(async (key) => [key, await rowReducer(key, row, list)]),
        );

        return Object.fromEntries(entries);
      });

      return { promises, columns: newColumns };
    },
    [columns, settings.columns],
  );

  const getData = useCallback(
    async (params) => {
      const parameters = {
        sorting: (params && params.sorting) || sorting,
        page:
          params && typeof params.page === "number" ? params.page : state.page,
        itemsCount: state.itemsCount,
        filters: (params && params.filters) || filters,
        itemsPerPage: (params && params.itemsPerPage) || itemsPerPage,
      };

      if (dataSource?.id) {
        const res = await fetchData(context, dataSource, parameters, name);
        const { promises, columns } = getDataWithPromises(
          res.data,
          res.columns,
        );
        const dataWithPromises = await Promise.all(promises);
        return { ...res, data: dataWithPromises, columns };
      }

      if (queryId) {
        const query = await getQueryById(queryId);
        const res = await fetchDataByQuery(
          context,
          query.query,
          parameters,
          name,
        );
        const { promises, columns } = getDataWithPromises(
          res.data,
          res.columns,
        );
        const dataWithPromises = await Promise.all(promises);
        return { ...res, data: dataWithPromises, columns };
      }

      if (objectId) {
        const dataObjectList = await getAvailableObjects();
        const dataObject = dataObjectList.find(
          (o) => parseInt(o.id) === parseInt(objectId),
        );

        const companies = await getCompanies();
        const company = companies.find(
          (c) => parseInt(c.id) === parseInt(context.userContext.companyId),
        );

        const dbName = dataObject?.databaseName || company.databaseName;
        const schemaName = dataObject?.schemaName || "dbo";
        const tableName = dataObject?.name;

        const query = `SELECT * FROM [${dbName}].[${schemaName}].[${tableName}]`;

        const res = await fetchDataByQuery(context, query, parameters, name);
        const { promises, columns } = getDataWithPromises(
          res.data,
          res.columns,
        );
        const dataWithPromises = await Promise.all(promises);
        return { ...res, data: dataWithPromises, columns };
      }

      return showErrorToast({
        reasonTitle: "DataSource is not defined.",
        location: `${name} dataSource.`,
        shortMessage:
          "At least one of these must be defined: dataSource, queryId or relatedObjectId.",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataSource,
      filters,
      name,
      page,
      sorting,
      itemsPerPage,
      getDataWithPromises,
    ],
  );

  const refreshData = useDebounceCallback((options) => {
    setField("loading", true);
    getData(options)
      .then(setState)
      .catch(showErrorToast)
      .finally(() => setField("loading", false));
  }, 1000);

  const onChange = async (type, value) => await setField(type, value);

  const { result, loading } = useDataLoading(
    name,
    getData,
    environment === "frontend"
      ? [dataSource]
      : [dataSource, filters, name, page, sorting, itemsPerPage],
    !settings.loading && dataSource?.id,
    1000,
  );

  useEffect(() => {
    setField("loading", true);
    if (result) {
      setState(result);
      setField("loading", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    setField("loading", loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const settingsColumns = useMemo(() => {
    const colsWithSettings = addSettingsToColumns(
      state.columns,
      settings.columns,
    );

    return colsWithSettings
      .sort((a, b) => parseInt(a.order) - parseInt(b.order))
      .map((c, idx) => ({ ...c, order: idx }))
      .sort((a, b) => parseInt(a.order) - parseInt(b.order));
  }, [state.columns, settings.columns]);

  const frontendData = useMemo(() => {
    if (environment !== "frontend") return [];
    if (state.data.length < 1) return [];
    let result = state.data;
    result = getFrontendFilteredData(result, filters);
    result = getFrontendSortedData(result, sorting);
    return result;
  }, [environment, filters, sorting, state.data]);

  return {
    state: {
      ...state,
      data: environment === "frontend" ? frontendData : state.data,
      columns: settingsColumns,
    },
    onChange,
    refreshData,
    getData,
  };
};
