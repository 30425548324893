import PropTypes from "prop-types";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { DatagridV2 } from "@echo/datagrid";
import { useCallback } from "react";
import { usePropertiesTable } from "./hooks/use-properties-table";

const ComponentPropertiesDesignerModal = ({
  open,
  onClose,
  onSubmit,
  initialSchemaObject,
  component,
}) => {
  const {
    data,
    columns,
    enableAdding,
    toggleAdding,
    selected,
    deleteSelected,
    actions,
    reset,
  } = usePropertiesTable(component, initialSchemaObject);

  const handleClose = (...args) => {
    if (onClose) {
      Promise.all([onClose(...args), reset()]);
    }
  };

  const handleSubmit = useCallback(
    (...args) => {
      if (onSubmit && onClose) {
        Promise.all([onSubmit(data, ...args), onClose()]);
      }
    },
    [data, onClose, onSubmit],
  );

  const mapDataElement = (element) => {
    const keys = Object.keys(element);

    return keys.reduce(
      (acc, curr) => ({ ...acc, [curr]: { value: element[curr] } }),
      {},
    );
  };

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxW="80vw" height="80vh" maxH="80vh">
        <ModalHeader>Edit component properties</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDirection="column"
          gap="5px"
          width="100%"
          overflow="hidden"
        >
          <Box display="flex" gap="10px">
            <Button onClick={toggleAdding}>Add property</Button>
            <Button onClick={deleteSelected}>Delete selected</Button>
          </Box>
          <Box height="100%" overflow="hidden">
            <DatagridV2
              data={data.map(mapDataElement)}
              columns={columns}
              actions={actions}
              page={0}
              filters={[]}
              sort={[]}
              itemsCount={data?.length ?? 0}
              loading={false}
              selectionMode={"multiple"}
              enableAdding={enableAdding}
              enableEdit
              settings={{ itemsPerPage: { value: 100 } }}
              contextActions={[]}
              selectedItems={selected}
              // disableFilters
              disableSummary
              disableFooter
              selectionContextActions={[]}
              summaryOptions={{}}
              summaryValues={[]}
              theme={"light"}
            />
          </Box>
          {/* <ComponentPropertiesDesigner
            schema={schemaObject}
            onSchemaChange={setSchemaObject}
          /> */}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ComponentPropertiesDesignerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialSchemaObject: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      propType: PropTypes.string.isRequired,
      propName: PropTypes.string.isRequired,
    }),
  ),
  component: PropTypes.object,
};

export default ComponentPropertiesDesignerModal;
