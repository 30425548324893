const filterFunc = (item, filter) => {
  const name = filter.name;
  const value = filter.values[0];

  if (item[name].options && item[name].options.length > 0) {
    const options = item[name].options;
    const f = options.find((opt) => opt.value === item[name].value);
    return f.label.toLowerCase().includes(value.toLowerCase());
  }

  return item?.[name]?.value?.toLowerCase().includes(value?.toLowerCase());
};

export const getFrontendFilteredData = (data, filters) => {
  if (filters.length < 1) return data;

  let result = [];

  filters.forEach((f) => {
    result = data.filter((i) => filterFunc(i, f));
  });

  return result;
};
