import { Quill } from "react-quill";

const Clipboard = Quill.import("modules/clipboard");

export class CustomClipboard extends Clipboard {
  onPaste(e) {
    const html = (e.clipboardData || window.clipboardData).getData("text/html");

    if (html) {
      e.preventDefault();

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const paragraphs = doc.querySelectorAll("p");
      paragraphs.forEach((p) => {
        if (p.hasAttribute("id")) {
          p.setAttribute("data-id", p.getAttribute("id"));
        }
      });

      const sanitizedHTML = doc.body.innerHTML;

      const delta = this.quill.clipboard.convert(sanitizedHTML);
      let selection = this.quill.getSelection();

      const ops = delta.ops.map((op) => {
        if (op.attributes && op.attributes["data-id"]) {
          op.attributes.id = op.attributes["data-id"];
          delete op.attributes["data-id"];
        }
        return op;
      });

      const selectionOps = [];

      if (selection?.index) {
        selectionOps.push({ retain: selection?.index || 0 });
      }

      if (selection?.length) {
        selectionOps.push({ delete: selection.length });
      }

      this.quill.updateContents(
        {
          ...delta,
          ops: [...selectionOps, ...ops],
        },
        "user",
      );

      const offset = ops
        .filter((op) => op?.insert)
        .map((op) => op.insert.length)
        .reduce((partialSum, x) => partialSum + x, 0);

      this.quill.setSelection((selection?.index || 0) + (offset || 0));
    } else {
      super.onPaste(e);
    }
  }
}
