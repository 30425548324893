import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import PropTypes from "prop-types";
import EditorComponent from "../../../../properties/properties-table/cell-components/editor-component";
import {
  extractValue,
  parseValue,
} from "../../../../../diagram-designer/components/modals/editor-tab/template-util";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";

const CodemirrorComponent = (props) => {
  const { rootProps, type, value, onChange, name, designerMode } = props;
  const { nodeRef, ...restRootProps } = rootProps;

  const [inputValue, setInputValue] = useDebouncedValueState(
    value,
    onChange,
    name,
  );

  const handleChange = (newValue) => {
    if ((newValue || "") !== (value || "")) {
      setInputValue(parseValue(newValue));
    }
  };

  return (
    <Box ref={nodeRef} {...restRootProps}>
      {designerMode ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
        >
          Codemirror component
        </Box>
      ) : (
        <EditorComponent
          type={type}
          value={extractValue(inputValue, type)}
          onChange={handleChange}
          options={{
            mode: type === "javascript" ? "text/javascript" : "text/x-pgsql",
            lineNumbers: true,
          }}
        />
      )}
    </Box>
  );
};

CodemirrorComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["sql", "javascript"]),
  designerMode: PropTypes.bool,
  rootProps: PropTypes.any,
};

export default withBaseComponent(CodemirrorComponent);
