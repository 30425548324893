import { useCallback, useState } from "react";
import { useSettings } from "./use-settings";
import { useData } from "./use-data";
import { useColumnsProcess } from "./use-columns-process";
import { showErrorToast } from "../../../../../echo-error-toast";
import { mapColumnOrder } from "../utils/map-column-order";
import { getSortValue } from "../utils/get-sort-value";

export const useDatagrid = (props) => {
  const {
    id,
    name,
    context,
    dataSource,
    onRowEdit,
    onRowAdd,
    objectId,
    queryId,
    disableSorting,
    environment,
  } = props;

  const disableHeader = props.hideHeaderColumns;

  const { resolvedColumns } = useColumnsProcess(props);

  const [addingNewRow, setAddingNewRow] = useState(false);

  const { settings, actions } = useSettings({
    id,
    context,
    dataSource,
    objectId,
    queryId,
    initialSettings: {
      disableHeader: !!disableHeader,
      itemsPerPage: environment === "frontend" ? null : 20,
    },
    conditions: {
      disableSorting,
    },
  });

  const { state, onChange, refreshData, getData } = useData(
    context,
    dataSource,
    settings,
    name,
    objectId,
    queryId,
    resolvedColumns,
    environment,
  );

  const onFilterChange = async (name, type, values, shouldSave) => {
    await actions.handleFilter(name, type, values, shouldSave);
  };

  const clearFilters = async () => {
    await actions.clearFilters();
  };

  const onPageChange = (value) => onChange("page", value);

  const onSortChange = async (name, value, ctrlKey) => {
    if (disableSorting) return;

    const newValue = getSortValue(value);
    await actions.handleSorting(name, newValue, ctrlKey);
  };

  const onColumnChange = async (name, prop, value) => {
    await actions.updateColumn(name, prop, value, state.columns);
  };

  const onColumnBatchChange = async (columns, userId, companyId) => {
    await actions.updateManyColumns(columns, userId, companyId);
  };

  const onUserColumnChange = async (columns, userId, companyId) => {
    await actions.updateUserColumns(columns, userId, companyId);
  };

  const onColumnOrderChange = useCallback(
    async (name, value) => {
      const f = state.columns.find((c) => c.name === name);
      const newColumns = state.columns.map((el) =>
        mapColumnOrder(el, { id: f.name, newPos: value, startPos: f.order }),
      );

      await actions.updateManyColumns(newColumns);
    },
    [actions, state.columns],
  );

  const onItemsPerPageChange = async (value) => {
    await actions.updateItemsPerPage(value);
  };

  const onHeaderVisibilityChange = async () => {
    await actions.switchDisableHeader();
  };

  const onFiltersVisibilityChange = async () => {
    await actions.switchDisableFilters();
  };

  const onNavPositionChange = async () => {
    await actions.switchNavPosition();
  };

  const onLockRowHeightChange = async () => {
    await actions.switchLockRowHeight();
  };

  const onHeaderWhiteSpaceChange = async () => {
    await actions.switchHeaderWhiteSpace();
  };

  const deleteSetting = async () => {
    await actions.deleteSetting();
  };

  const toggleTreeOpen = async () => {
    await actions.switchTreeIsOpen();
  };

  const onTreeWidthChange = async (value) => {
    await actions.updateTreeWidth(value);
  };

  const addEmptyRow = () => setAddingNewRow((prev) => !prev);

  const handleRowAdd = async (row) => {
    if (!onRowAdd) {
      showErrorToast({
        reasonTitle: "Datagrid: onRowAdd",
        message: "Process onRowAdd is not defined.",
      });
      return;
    }

    try {
      await onRowAdd(row);
      setAddingNewRow(false);
      await refreshData();
    } catch (e) {
      showErrorToast(e);
    }
  };

  const handleRowEdit = async (row) => {
    if (!onRowEdit) {
      showErrorToast({
        reasonTitle: "Datagrid: onRowEdit",
        message: "Process onRowEdit is not defined.",
      });
      return;
    }

    try {
      await onRowEdit(row);
    } catch (e) {
      showErrorToast(e);
    }
  };

  const api = {
    onColumnChange,
    onColumnBatchChange,
    onUserColumnChange,
    onColumnOrderChange,
    onPageChange,
    refreshData,
    getData,
    clearFilters,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onHeaderWhiteSpaceChange,
    onNavPositionChange,
    onLockRowHeightChange,
    onRowEdit: handleRowEdit,
    onRowAdd: handleRowAdd,
    addEmptyRow,
    onSortChange: (name, value, ctrlKey) => {
      onSortChange(name, value, ctrlKey);
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      onPageChange(0);
    },
    onFilterChange: (name, type, values, shouldSave) => {
      onFilterChange(name, type, values, shouldSave);
      onPageChange(0);
    },
    deleteSetting,
    toggleTreeOpen,
    onTreeWidthChange,
  };

  return {
    state: {
      ...state,
      data: state.data,
      columns: state.columns.filter((c) => c.name.toLowerCase() !== "checkbox"),
      settings: {
        ...settings,
        columns: state.columns.filter(
          (c) => c.name.toLowerCase() !== "checkbox",
        ),
        itemsPerPage: { value: settings.itemsPerPage },
      },
    },
    api,
    options: {
      addingNewRow,
    },
  };
};
