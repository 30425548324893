import React, { createContext, useEffect, useRef, useState } from "react";
import { subtract, add } from "../utils/math-operations";
import { isEqualOrGreater, isEqualOrLower } from "../utils/compare";
import { formatValue, getBaseValue } from "../utils/format-input-value";
import { isKeyAllowed } from "../utils/validation";
import PropTypes from "prop-types";
import { deleteInvalidCharacters } from "../utils/delete-invalid-characters";

export const InputContext = createContext();

export const InputContextProvider = (props) => {
  const {
    children,
    value,
    onChange,
    onBlur,
    step,
    min,
    max,
    format = "# ##0.00",
    onKeyDown,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const increment = (step) =>
    setInputValue((prev) => {
      const baseValue = getBaseValue(prev, format);
      const newValue = add(baseValue, step).string;
      if (max < parseFloat(newValue)) return max.toString();
      return formatValue(newValue, format);
    });

  const decrement = (step) =>
    setInputValue((prev) => {
      const baseValue = getBaseValue(prev, format);
      const newValue = subtract(baseValue, step).string;
      if (min > parseFloat(newValue)) return min.toString();
      return formatValue(newValue, format);
    });

  let timeoutRef = useRef(null);
  const handleChange = (e) => {
    const value = deleteInvalidCharacters(e.target.value, format);
    setInputValue(value);
    // if (!value || value === "") {
    //   onChange(null);
    // } else {
    //   const base = getBaseValue(value, format);
    //   const parsed = parseFloat(base);
    //   onChange(parsed);
    // }
    // clearTimeout(timeoutRef.current);
    // timeoutRef.current = setTimeout(() => {
    //   if (!value || value === "") {
    //     onChange(null);
    //   } else {
    //     const formatted = formatValue(value, format);
    //     const base = getBaseValue(formatted, format);
    //     const parsed = parseFloat(base);
    //     onChange(parsed);
    //   }
    // }, 500);
  };

  const handleMinMaxValue = (value, format) => {
    const result = formatValue(value, format);
    setInputValue(result);

    const base = getBaseValue(value, format);
    onChange(parseFloat(base));
    if (onBlur) onBlur(parseFloat(base));
  };

  const handleBlur = (e) => {
    const value = e.target.value;
    if (!value || value === "") {
      onChange(null);
      if (onBlur) onBlur(null);
      return;
    }

    const formatted = formatValue(value, format);
    const base = getBaseValue(formatted, format);

    if (max && isEqualOrGreater(base, max)) {
      handleMinMaxValue(max, format);
      return;
    }

    if (min && isEqualOrLower(base, min)) {
      handleMinMaxValue(min, format);
      return;
    }

    setInputValue(formatted);
    onChange(parseFloat(base));
    if (onBlur) onBlur(parseFloat(base));
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey) return;
    if (!isKeyAllowed(e.key, format)) e.preventDefault();
    if (onKeyDown) onKeyDown(e);
    if (document.activeElement === inputRef.current) {
      switch (e.key) {
        case "ArrowUp":
          increment(step);
          break;
        case "ArrowDown":
          decrement(step);
          break;
      }
    }
  };

  useEffect(() => {
    const formatted = formatValue(value, format);
    setInputValue(formatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, format]);

  const inputActions = {
    handleChange,
    handleBlur,
    handleKeyDown,
  };

  const stepperActions = {
    increment,
    decrement,
  };

  const contextValue = {
    value: inputValue,
    step,
    inputRef,
    inputActions,
    stepperActions,
  };

  return (
    <InputContext.Provider value={contextValue}>
      {children}
    </InputContext.Provider>
  );
};

InputContextProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};
