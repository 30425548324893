import { subDays } from "date-fns";
import { useCallback, useMemo } from "react";
import { List, ListItem } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FullDayEvent } from "./full-day-event";

export const FullDayEventsList = ({
  events,
  calendars,
  onEventEdit,
  onEventClick,
  sectionId,
  showAllEvents,
  maxEvents,
}) => {
  const handleDrop = useCallback(
    (item, newArea) => {
      if (newArea === null || newArea === undefined) return;
      const pos = item.startPos;
      const newPos = newArea;
      const event = events.find((ev) => ev.id === item.id);
      const diff = pos - newPos;
      const newEvent = {
        ...event,
        from: subDays(event.from, diff),
        to: subDays(event.to, diff),
      };

      if (onEventEdit) onEventEdit(newEvent);
    },
    [events, onEventEdit],
  );

  const rows = useMemo(() => {
    if (maxEvents > 2 && !showAllEvents) return 3;
    return maxEvents;
  }, [maxEvents, showAllEvents]);

  return (
    <List
      gap="3px"
      height="100%"
      overflow="auto"
      display="grid"
      gridTemplateRows={`repeat(${rows}, minmax(10px, 1fr))`}
    >
      {events
        .filter((_, idx) => {
          if (showAllEvents) return true;
          if (idx < 2) return true;
          return false;
        })
        .map((ev) => (
          <FullDayEvent
            {...ev}
            background={
              ev.color ||
              calendars.find((c) => c.id === ev.calendarId).background.value
            }
            sectionId={sectionId}
            onDrop={handleDrop}
            onClick={() => onEventClick(ev)}
            key={`${ev.calendarId}-${ev.id}`}
          />
        ))}
      {events.length > 2 && !showAllEvents && (
        <ListItem fontSize=".8rem">
          jeszcze {events.filter((_, idx) => idx >= 2).length}
        </ListItem>
      )}
    </List>
  );
};

FullDayEventsList.propTypes = {
  events: PropTypes.array,
  calendars: PropTypes.array,
  onEventEdit: PropTypes.func,
  onEventClick: PropTypes.func,
  sectionId: PropTypes.number,
  showAllEvents: PropTypes.bool,
  maxEvents: PropTypes.number,
};
