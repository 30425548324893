import { useMemo } from "react";
import { getGroupIdFromDatasource } from "../utils/get-group-id";

export const useSettingKey = (context, dataSource, objectId, queryId) => {
  const contextQueryId = useMemo(() => {
    if (!context) return null;
    if (!context.page) return null;
    if (!context.page.queryParams) return null;
    if (typeof context.page.queryParams !== "object") return null;

    const keys = Object.keys(context.page.queryParams);
    const queryIdKey = keys.find((k) => k.toLowerCase() === "queryid");

    if (!queryIdKey) return null;
    return context.page.queryParams[queryIdKey];
  }, [context]);

  const settingsQueryId = useMemo(
    () => queryId || dataSource?.staticParams?.queryId || contextQueryId,
    [queryId, dataSource?.staticParams?.queryId, contextQueryId],
  );

  const groupId = useMemo(
    () => getGroupIdFromDatasource(dataSource),
    [dataSource],
  );

  const key = useMemo(
    () => `${objectId || "%"}_${settingsQueryId || "%"}_${groupId || "%"}`,
    [objectId, settingsQueryId, groupId],
  );

  return { settingKey: key };
};
