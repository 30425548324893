import { baseGet, basePost } from "./base-fetch/base-fetch";
import getToken from "./token-service";
import { getApiUrl } from "./utils/api-uri";

export const getUserByLogin = (userName) =>
  new Promise((resolve, reject) => {
    fetch(
      getApiUrl() +
        "User/userByLogin?" +
        new URLSearchParams({
          login: userName,
        }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });

export const getUserList = (search) =>
  new Promise((resolve, reject) => {
    fetch(
      getApiUrl() +
        "User/userList?" +
        new URLSearchParams({
          search,
        }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });

export const setUserPassword = (
  id,
  password,
  newPassword,
  newComparePassword,
) =>
  new Promise((resolve, reject) => {
    fetch(getApiUrl() + "User/changePassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        id,
        password,
        newPassword,
        newComparePassword,
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });

export const getUserData = (userName) =>
  new Promise((resolve, reject) => {
    fetch(
      getApiUrl() +
        "User/userDataByLogin?" +
        new URLSearchParams({
          login: userName,
        }),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });

export const saveSetting = (setting, settingsType = "user") =>
  basePost("User/SaveUserSetting", setting, { settingsType });

export const getSetting = (userId, roleId, key) =>
  baseGet("User/GetUserSetting", { userId, roleId, key });
