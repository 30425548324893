import React from "react";
import { Select } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const ComboEditCell = (props) => {
  const { onChange, value, options, autoFocus, onKeyDown } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <Select
      autoFocus={autoFocus}
      onChange={handleChange}
      value={value}
      onKeyDown={onKeyDown}
    >
      <option value={null} />
      {Array.isArray(options) &&
        options?.map((opt, idx) => {
          const [value, label] = Object.values(opt);

          return (
            <option value={value} key={idx}>
              {label}
            </option>
          );
        })}
    </Select>
  );
};

ComboEditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  autoFocus: PropTypes.bool,
};
