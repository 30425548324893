import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getId } from "../utils/get-id";
import { useInlineEdit } from "../hooks/use-inline-edit";

export const InlineEditContext = createContext();

export const InlineEditContextProvider = ({ children, value }) => {
  const { data, columns, enableEdit } = value;
  const [editMode, setEditMode] = useState(false);

  const {
    focusedRow,
    focusedColumn,
    focusRow,
    focusColumn,
    focusNextColumn,
    focusNextRow,
    onKeyDown,
  } = useInlineEdit(data, columns);

  const toggleEditMode = () => setEditMode((prev) => !prev);
  const enterEditMode = () => setEditMode(true);
  const exitEditMode = () => setEditMode(false);

  const onCellClick = useCallback(
    (cell) => {
      if (enableEdit && cell.editable) {
        enterEditMode();
        focusRow(cell.row);
        focusColumn(cell.colName);
      }
    },
    [editMode],
  );

  const getIsFocused = useCallback(
    (row, column) => {
      const rowId = getId(row).value;
      const colName = column.name;

      if (focusedRow === rowId && focusedColumn === colName) {
        return true;
      }

      return false;
    },
    [focusedRow, focusedColumn],
  );

  const getIsEditable = useCallback(
    (row) => editMode && focusedRow === getId(row).value,
    [focusedRow, editMode],
  );

  const onFocus = (row, column) => {
    focusRow(row);
    focusColumn(column);
  };

  useEffect(() => {
    if (!focusedRow) {
      exitEditMode();
    }
  }, [focusedRow]);

  return (
    <InlineEditContext.Provider
      value={{
        onCellClick,
        getIsFocused,
        onKeyDown,
        exitEditMode,
        editMode,
        focusedRow,
        getIsEditable,
        onFocus,
      }}
    >
      {children}
    </InlineEditContext.Provider>
  );
};

export const useInlineEditContext = () => {
  const context = useContext(InlineEditContext);
  if (!context) throw new Error("Inline edit context is not initialized.");
  return context;
};
