import { alertBlock } from "./blocks/alert-block";
import { getBackendBlock } from "./blocks/backend-block";
import { getChangeStateBlock } from "./blocks/change-state-block";
import { closePageBlock } from "./blocks/close-page-block";
import { conditionBlock } from "./blocks/condition-block";
import { createFormBlock } from "./blocks/create-form-block";
import { deleteFormBlock } from "./blocks/delete-form-block";
import { getDiagramBlock } from "./blocks/diagram-block";
import { executeCallerComponentProp } from "./blocks/execute-caller-component-prop";
import { executeComponentProp } from "./blocks/execute-component-prop";
import { getCallerComponentProp } from "./blocks/get-caller-component-prop";
import { getComponentProp } from "./blocks/get-component-prop";
import { getJsBlock } from "./blocks/js-block";
import { readFormBlock } from "./blocks/read-form-block";
import { sendEventBlock } from "./blocks/send-event-block";
import { openTabBlock } from "./blocks/open-tab-block";
import { updateFormBlock } from "./blocks/update-form-block";
import { getChangeComponentPropBlock } from "./blocks/get-change-component-prop";
import { getSwitchBlock } from "./blocks/get-switch-block";
import { readStateBlock } from "./blocks/read-state-block";
import { getRefreshDatagridBlock } from "./blocks/get-refresh-datagrid-component";
import { executeCallback } from "./blocks/execute-callback";
import { executeFrontendBlock } from "./blocks/execute-block";
import { openFileBlock } from "./blocks/open-file-block";
import { storeFile } from "./blocks/store-file";
import { addCalendarEvent, editCalendarEvent } from "./blocks/google-blocks";
import { baseCrud } from "./blocks/base-crud-block";
import { showToastBlock } from "./blocks/show-toast-block";
import { openModalBlock } from "./blocks/open-modal-block";
import { readValueBlock } from "./blocks/read-value-block";
import { remountComponentBlock } from "./blocks/remount-component-block";
import { loopBlock } from "./blocks/loop-block";
import { messageBlock } from "./blocks/message-block";
import { openExternalUrlBlock } from "./blocks/open-www-block";
import { changeModelProperty } from "./blocks/change-model-property";
import { hashValueBlock } from "./blocks/hash-value-block";
import { boundaryBlock } from "./blocks/boundary-block";
import { downloadFileBlock } from "./blocks/download-file-block";
import { componentApiBlock } from "./blocks/component-api-block";
import { sendEmailBlock } from "./blocks/email-blocks/send-email-block";
import { getEmailsListBlock } from "./blocks/email-blocks/get-mails-list-block";
import { readMailBlock } from "./blocks/email-blocks/read-mail-block";
import { markMailsReadBlock } from "./blocks/email-blocks/mark-mails-read";
import { getMailLabelsBlock } from "./blocks/email-blocks/get-mail-labels-block";
import { deleteMailsBlock } from "./blocks/email-blocks/delete-mails-block";
import { googleAuthBlock } from "./blocks/email-blocks/google-auth-block";
import { validateFormBlock } from "./blocks/validation-blocks/validate-form-block";
import { crudActionBlock } from "./blocks/form-blocks/crud-action-block";
import {
  getClipboardData,
  getClipboardDataBlock,
} from "./blocks/get-clipboard-data";

export const getBlockExecutor = (block, componentContext) => {
  if (block.type === 0) {
    return getBackendBlock(block, componentContext);
  }

  switch (block.executeType) {
    case -1:
      return boundaryBlock(block, componentContext);
    case 0:
      return getDiagramBlock(block, componentContext);
    case 4:
      return getJsBlock(block, componentContext);
    case 5:
      return conditionBlock(block, componentContext);
    case 6:
      return openTabBlock(block, componentContext);
    case 7:
      return getChangeStateBlock(block, componentContext);
    case 8:
      return createFormBlock(block, componentContext);
    case 9:
      return readFormBlock(block, componentContext);
    case 10:
      return updateFormBlock(block, componentContext);
    case 11:
      return deleteFormBlock(block, componentContext);
    case 16:
      return crudActionBlock(block, componentContext);
    case 17:
      return showToastBlock(block, componentContext);
    case 27:
      return readStateBlock(block, componentContext);
    case 28:
      return alertBlock(block, componentContext);
    case 30:
      return closePageBlock(block, componentContext);
    case 31:
      return executeComponentProp(block, componentContext);
    case 32:
      return getComponentProp(block, componentContext);
    case 33:
      return getCallerComponentProp(block, componentContext);
    case 35:
      return sendEventBlock(block, componentContext);
    case 38:
      return executeCallerComponentProp(block, componentContext);
    case 41:
      return getChangeComponentPropBlock(block, componentContext);
    case 42:
      return getSwitchBlock(block, componentContext);
    case 44:
      return getRefreshDatagridBlock(block, componentContext);
    case 49:
      return executeCallback(block, componentContext);
    case 50:
      return executeFrontendBlock(block, componentContext);
    case 52:
      return openFileBlock(block, componentContext);
    case 53:
      return storeFile(block, componentContext);
    case 54:
      return addCalendarEvent(block, componentContext);
    case 55:
      return editCalendarEvent(block, componentContext);
    case 56:
      return baseCrud(block, componentContext);
    case 57:
      return openModalBlock(block, componentContext);
    case 58:
      return readValueBlock(block, componentContext);
    case 60:
      return remountComponentBlock(block, componentContext);
    case 61:
      return validateFormBlock(block, componentContext);
    case 62:
      return loopBlock(block, componentContext);
    case 63:
      return messageBlock(block, componentContext);
    case 64:
      return openExternalUrlBlock(block, componentContext);
    case 65:
      return hashValueBlock(block, componentContext);
    case 66:
      return changeModelProperty(block, componentContext);
    case 68:
      return downloadFileBlock(block, componentContext);
    case 69:
      return componentApiBlock(block, componentContext);
    case 70:
      return sendEmailBlock(block, componentContext);
    case 71:
      return getEmailsListBlock(block, componentContext);
    case 72:
      return readMailBlock(block, componentContext);
    case 73:
      return markMailsReadBlock(block, componentContext);
    case 74:
      return getMailLabelsBlock(block, componentContext);
    case 75:
      return deleteMailsBlock(block, componentContext);
    case 76:
      return googleAuthBlock(block, componentContext);
    case 81:
      return getClipboardDataBlock(block, componentContext);
    default:
      throw new Error(`Unexpected block type: ${block.executeType}`);
  }
};
