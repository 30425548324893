import React from "react";
import PropTypes from "prop-types";
import { DATAGRID_BODY_TESTID } from "../../utils/test-ids";
import { ContextMenuWrapper } from "../context-menu-wrapper/context-menu-wrapper";
import { Tbody } from "@chakra-ui/react";
import { useContextMenu } from "@echo/ui";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { DragSection } from "@echo/draggable";

export const BodyWithContextMenu = ({ children, ...rest }) => {
  const { gridContextActions } = useDatagridContext();
  const {
    clickPosition,
    closeContextMenu,
    isContextMenuOpened,
    openContextMenu,
  } = useContextMenu();

  return (
    <ContextMenuWrapper
      isOpen={isContextMenuOpened}
      onClose={closeContextMenu}
      clickPosition={clickPosition}
      actions={gridContextActions}
    >
      <DragSection context="datagrid" id="datagrid-body">
        <Tbody
          {...rest}
          data-testid={DATAGRID_BODY_TESTID}
          onContextMenu={openContextMenu}
        >
          {children}
        </Tbody>
      </DragSection>
    </ContextMenuWrapper>
  );
};

BodyWithContextMenu.propTypes = {
  children: PropTypes.node,
};
