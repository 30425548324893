import { useCallback, useMemo, useState } from "react";
import { getId } from "../utils/get-id";

export const useInlineEdit = (data, columns) => {
  const [focusedRow, setFocusedRow] = useState(null);
  const [focusedColumn, setFocusedColumn] = useState(null);

  const editableColumns = useMemo(
    () => columns.filter((c) => c.editable),
    [columns],
  );

  const focusRow = (row) => setFocusedRow(row);
  const focusColumn = (column) => setFocusedColumn(column);

  const focusNextRow = useCallback(() => {
    setFocusedRow((prev) => {
      const idx = data.findIndex((r) => getId(r)?.value === prev);
      if (idx === -1) return prev;

      const nextRow = data[idx + 1];
      if (!nextRow) return null;
      return getId(nextRow)?.value;
    });
  }, [data]);

  const focusPrevRow = useCallback(() => {
    setFocusedRow((prev) => {
      const idx = data.findIndex((r) => getId(r)?.value === prev);
      if (idx === -1) return prev;

      if (idx - 1 < 0) return null;

      const prevRow = data[idx - 1];
      return getId(prevRow)?.value;
    });
  }, [data]);

  const focusPrevColumn = useCallback(() => {
    setFocusedColumn((prev) => {
      const idx = editableColumns.findIndex((c) => c.name === prev);
      if (idx === -1) return prev;

      if (idx - 1 < 0) return prev;

      const prevColumn = editableColumns[idx - 1];
      return prevColumn.name;
    });
  }, [editableColumns]);

  const focusNextColumn = useCallback(() => {
    setFocusedColumn((prev) => {
      const idx = editableColumns.findIndex((c) => c.name === prev);
      if (idx === -1) return prev;

      const nextColumn = editableColumns[idx + 1];
      if (!nextColumn) return prev;
      return nextColumn.name;
    });
  }, [editableColumns]);

  const onKeyDown = useCallback(
    (e) => {
      // switch (e.key) {
      //   case "Tab":
      //     if (e.shiftKey) {
      //       focusPrevColumn();
      //     } else {
      //       focusNextColumn();
      //     }
      //     break;
      //   case "Enter":
      //     if (e.shiftKey) {
      //       focusPrevRow();
      //     } else {
      //       focusNextRow();
      //     }
      //     break;
      // }
    },
    [focusNextRow, focusNextColumn],
  );

  return {
    focusedRow,
    focusedColumn,
    focusRow,
    focusColumn,
    focusNextColumn,
    focusNextRow,
    onKeyDown,
  };
};
