const convertText = (text) =>
  text
    .split("\n")
    .filter((row) => row.trim() !== "")
    .map((row) => row.replace("\r", "").split("\t"));

const arrayToObjectData = (data) => data.map((arr) => ({ ...arr }));

const mapDataWithColumns = (data, columns) =>
  data.map((arr) =>
    arr.reduce((acc, curr, idx) => ({ ...acc, [columns[idx]]: curr }), {}),
  );

export const getClipboardDataBlock = (block) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { includeColumnNames } = staticParams;

      const clipboardPromise = () =>
        new Promise((resolve, reject) => {
          navigator.clipboard
            .read()
            .then((items) => {
              const item = items[0];
              const types = item.types;
              const isPlainText =
                types.filter((t) => t !== "text/plain").length === 0;

              navigator.clipboard
                .readText()
                .then((text) => {
                  if (isPlainText) {
                    resolve({ data: text, type: "text" });
                  } else {
                    const type = "table";
                    const parsedData = convertText(text);
                    if (includeColumnNames) {
                      const columns = parsedData[0];
                      const arrData = parsedData.slice(1);
                      const data = mapDataWithColumns(arrData, columns);
                      resolve({ data, type });
                    } else {
                      const data = arrayToObjectData(parsedData);
                      resolve({ data, type });
                    }
                  }
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((err) => {
              reject(err);
            });
        });

      try {
        const { data, type } = await clipboardPromise();
        const jsonData = JSON.stringify(data);
        const queryParams = [
          { name: "jsonData", value: jsonData },
          { name: "jsonDataType", value: type },
        ];

        return {
          ...staticParams,
          queryParams,
          data,
          __resultCondition: type,
        };
      } catch (err) {
        throw new Error(err);
      }
    },
  };
};
