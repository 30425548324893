import { setLocalStorageItem } from "../../utils/local-storage/local-storage";
import { localStorageKeys } from "../../utils/local-storage/local-storage-keys";
import getToken from "../token-service";
import { getApiUrl } from "../utils/api-uri";

const getQueryParams = (queryParams = {}) => {
  const result = { ...queryParams };
  let fixedArraysParams = [];
  for (let key of Object.keys(result)) {
    if (Array.isArray(result[key])) {
      fixedArraysParams = [
        ...fixedArraysParams,
        ...result[key].map((s) => [key, s]),
      ];
      delete result[key];
    }
  }
  const resultString = new URLSearchParams(result).toString();
  const fixedArrayParamsString = new URLSearchParams(
    fixedArraysParams,
  ).toString();
  return [resultString, fixedArrayParamsString].filter((ps) => !!ps).join("&");
};

const logout = () => {
  const userDataKey = localStorageKeys.USER_DATA;
  setLocalStorageItem(userDataKey, { sessionExpired: true });
  window.location.reload();
};

export const basePost = (path, body, queryParams = {}) => {
  const params = (queryParams ? "?" : "") + new URLSearchParams(queryParams);

  return new Promise((resolve, reject) => {
    const token = getToken();
    if (token) {
      fetch(getApiUrl() + path + params, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 401) {
              logout();
            } else {
              const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
              if (isJson) {
                response.json().then(reject).catch(reject);
              } else {
                reject(response);
              }
            }
          } else {
            response
              .json()
              .then((result) => resolve(result))
              .catch(reject);
          }
        })
        .catch(reject);
    }
  });
};

export const buildFetch = (path, queryParams = {}, method = "GET") => {
  const params = (queryParams ? "?" : "") + getQueryParams(queryParams);
  const token = getToken();
  if (token) {
    return fetch(getApiUrl() + path + params, {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    logout();
    return undefined;
  }
};

export const baseGet = (path, queryParams = {}) =>
  new Promise((resolve, reject) => {
    buildFetch(path, queryParams)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            logout();
          } else {
            reject(response.statusText);
          }
        } else {
          response
            .json()
            .then((result) => {
              resolve(result);
            })
            .catch(reject);
        }
      })
      .catch(reject);
  });

export const baseDelete = (path, queryParams = {}) =>
  new Promise((resolve, reject) => {
    buildFetch(path, queryParams, "DELETE")
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            logout();
          } else {
            reject(response.statusText);
          }
        } else {
          resolve();
        }
      })
      .catch(reject);
  });

export const GET = async (url, queryParams = {}) => {
  const params = (queryParams ? "?" : "") + getQueryParams(queryParams);
  const token = getToken();
  if (token) {
    return await fetch(url + params, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    logout();
    return undefined;
  }
};

export const POST = async (url, queryParams = {}, body) => {
  const params = (queryParams ? "?" : "") + getQueryParams(queryParams);
  const token = getToken();
  if (token) {
    return await fetch(url + params, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
  } else {
    logout();
    return undefined;
  }
};
