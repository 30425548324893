import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { DatagridV2 } from "@echo/datagrid";
import { Box, useColorMode, useDisclosure } from "@chakra-ui/react";
import { useDatagrid } from "./hooks/use-datagrid-v2";
import { useSelection } from "./hooks/use-selection";
import ColumnVisibilityManager from "../datagrid-component/header/column-visibility-manager/column-visibility-manager";
import { ColumnSettings } from "../datagrid-component/column-settings/column-settings";
import { getDatagridColumnsPropType } from "./column-prop-type";
import { useEffect } from "react";
import { getDatagridActionsPropType } from "./action-prop-type";
import { useActions } from "./hooks/use-actions";
import { ActionsList } from "./components/actions-list";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { useSummary } from "./hooks/use-summary";
import { useContextActions } from "./hooks/use-context-actions";
import { useTableActions } from "./hooks/use-table-actions";
import { getRelatedObjectPropType } from "./related-object-prop-type";
import { getQueryPropType } from "./query-prop-type";
import TreeViewComponent from "../tree-view-component/tree-view.component";
import { useTreeView } from "./hooks/use-tree-view";

const DatagridV2Component = (props) => {
  const {
    rootProps,
    nodeRef,
    groupsDataSource,
    groupsContextMenuDataSource,
    onRowClick,
    onRowDoubleClick,
    onSelectChange,
    selectionMode,
    disableNavOnLoading,
    actions: defaultActions,
    pageSizeSelectOptions,
    headerFontSize,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
    isCombo,
    filterColumnName,
    filterValue,
    isReadOnly,
    relatedObjectId,
    environment = "backend",
  } = props;

  const { colorMode } = useColorMode();
  const objectId = useResolveProp(relatedObjectId, true);
  const queryId = useResolveProp(props.queryId, true);
  const isReadOnlyState = useResolveProp(isReadOnly, true);

  const { value, onChange } = useTreeView(props.dataSource);

  const { state, api, options } = useDatagrid({
    ...props,
    objectId,
    queryId,
    dataSource: value,
    environment,
  });

  const { onFilterChange } = api;
  const { summaryValues, summaryOptions } = useSummary(state.summary);
  const { selectedItems, ...selectionFunctions } = useSelection(
    state.data,
    onSelectChange,
    selectionMode,
  );

  const {
    isOpen: isColumnsManagerOpen,
    onClose: onColumnsManagerClose,
    onOpen: onColumnsManagerOpen,
  } = useDisclosure();

  const {
    isOpen: isColumnsSettingsOpen,
    onClose: onColumnsSettingsClose,
    onOpen: onColumnsSettingsOpen,
  } = useDisclosure();

  const { contextActions, selectionContextActions } = useContextActions(
    { ...state, selectedItems },
    {
      ...api,
      ...selectionFunctions,
      onColumnsSettingsOpen,
      onColumnsManagerOpen,
    },
  );

  const tableActions = useTableActions({
    ...api,
    ...selectionFunctions,
    onRowClick,
    onRowDoubleClick,
  });

  const { actions } = useActions(
    props,
    { ...state, selectedItems },
    { ...api, ...selectionFunctions },
  );

  useEffect(() => {
    let timeout;

    if (filterColumnName) {
      timeout = setTimeout(() => {
        onFilterChange(
          filterColumnName,
          "contains",
          [filterValue === "" ? null : filterValue],
          false,
        );
      }, 500);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterColumnName, filterValue]);

  const getActions = (props, actions, defaultActions) =>
    props.designerMode ? defaultActions : actions;

  return (
    <Box
      display="flex"
      ref={nodeRef}
      className={`$data-grid`}
      {...rootProps}
      height="100%"
      width="100%"
      overflowX="hidden"
      padding="5px"
      gap="10px"
    >
      {(props.designerMode || groupsDataSource) && (
        <TreeViewComponent
          id="datagrid-tree-view"
          dataSource={groupsDataSource}
          contextMenuDataSource={groupsContextMenuDataSource}
          value={2}
          onChange={onChange}
          width={state.settings.treeWidth}
          isOpen={state.settings.treeIsOpen}
          context={props.context}
          rootProps={{ style: { width: `${state.settings.treeWidth}px` } }}
          onWidthChange={api.onTreeWidthChange}
          onOpenChange={api.toggleTreeOpen}
          designerMode={props.designerMode}
          component={{ displayName: "Groups" }}
        />
      )}
      <Box
        display="flex"
        overflowX="hidden"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        gap="10px"
      >
        <ActionsList
          actions={getActions(props, actions, defaultActions)}
          designerMode={props.designerMode}
        />
        <ColumnVisibilityManager
          isOpen={isColumnsManagerOpen}
          onClose={onColumnsManagerClose}
          columns={state.settings.columns}
          onChange={tableActions.onColumnChange}
        />
        <ColumnSettings
          isOpen={isColumnsSettingsOpen}
          onClose={onColumnsSettingsClose}
          defaultColumns={state.settings.columns}
          settingsId={state.settings.id}
          settingsKey={state.settings.key}
          onChange={tableActions.onColumnBatchChange}
          onSaveForUser={api.onUserColumnChange}
          sourceObject={state.source}
          isCombo={isCombo}
          actions={[
            {
              name: "Reset",
              buttonProps: { colorScheme: "red" },
              action: tableActions.deleteSetting,
            },
          ]}
          // eslint-disable-next-line react/prop-types
          parent={props?.component}
        />
        <DatagridV2
          data={state.data}
          columns={state.columns}
          actions={tableActions}
          page={state.page}
          filters={state.settings.filters}
          sort={props.disableSorting ? [] : state.settings.sorting}
          itemsCount={state.itemsCount}
          loading={state.loading}
          selectionMode={isReadOnlyState ? "none" : props.selectionMode}
          enableAdding={options.addingNewRow}
          enableEdit={isReadOnlyState ? false : props.enableEdit}
          settings={state.settings}
          contextActions={contextActions}
          selectedItems={selectedItems}
          disableFilters={props.hideFilterRow || state.settings.disableFilters}
          disableHeader={
            props.hideHeaderColumns || state.settings.disableHeader
          }
          disableFooter={props.hideStatusRow}
          disableSummary={props.hideFooter}
          disablePagination={disableNavOnLoading && state.loading}
          pageSizeSelectOptions={pageSizeSelectOptions}
          headerFontSize={headerFontSize}
          rowFontSize={rowFontSize}
          cellWhiteSpace={cellWhiteSpace}
          cellLinesNumber={cellLinesNumber}
          navPosition={state.settings.navPosition}
          selectionContextActions={selectionContextActions}
          summaryOptions={summaryOptions}
          summaryValues={summaryValues}
          theme={colorMode}
        />
      </Box>
    </Box>
  );
};

DatagridV2Component.propTypes = {
  name: PropTypes.string,
  dataSource: PropTypes.func,
  relatedObjectId: getRelatedObjectPropType(PropTypes.number),
  queryId: getQueryPropType(PropTypes.number),
  groupsDataSource: PropTypes.func,
  groupsContextMenuDataSource: PropTypes.func,

  columns: getDatagridColumnsPropType(PropTypes.object),
  actions: getDatagridActionsPropType(PropTypes.object),

  environment: PropTypes.oneOf(["frontend", "backend"]),

  context: PropTypes.any,
  rootProps: PropTypes.any,
  nodeRef: PropTypes.any,

  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowAdd: PropTypes.func,
  onRowEdit: PropTypes.func,
  onSelectChange: PropTypes.func,

  hideHeaderColumns: PropTypes.bool,
  hideFilterRow: PropTypes.bool,
  hideStatusRow: PropTypes.bool,
  hideFooter: PropTypes.bool,
  disableSorting: PropTypes.bool,
  disableNavOnLoading: PropTypes.bool,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  enableAdding: PropTypes.bool,
  enableEdit: PropTypes.bool,
  isReadOnly: PropTypes.bool,

  columnKey: PropTypes.string,
  designerMode: PropTypes.bool,

  rowColor: PropTypes.func,
  pageSizeSelectOptions: PropTypes.array,

  headerFontSize: PropTypes.string,
  rowFontSize: PropTypes.string,
  cellWhiteSpace: PropTypes.string,
  cellLinesNumber: PropTypes.string,

  isCombo: PropTypes.bool,
  filterColumnName: PropTypes.string,
  filterValue: PropTypes.string,
};

export default withBaseComponent(DatagridV2Component);
