import { getId } from "./get-id";

const getValue = (item) => {
  if (item.options && item.options.length > 0) {
    return item.options.find((opt) => opt.value === item.value).label;
  }

  return item.value;
};

const sortById = (a, b) => parseInt(getId(a).value) - parseInt(getId(b).value);

const sortAsc = (a, b) => {
  const aValue = getValue(a);
  const bValue = getValue(b);

  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

const sortDesc = (a, b) => {
  const aValue = getValue(a);
  const bValue = getValue(b);

  if (aValue < bValue) {
    return 1;
  }
  if (aValue > bValue) {
    return -1;
  }
  return 0;
};

export const getFrontendSortedData = (data, sorting) => {
  if (sorting.length < 1) return data.sort(sortById);

  let result = [];

  sorting.forEach((s) => {
    const name = s.name;
    if (s.value === "desc") {
      result = data.sort((a, b) => sortDesc(a[name], b[name]));
    } else {
      result = data.sort((a, b) => sortAsc(a[name], b[name]));
    }
  });

  return result;
};
