import { useCallback, useContext, useEffect, useMemo } from "react";
import { useSettingsReducer } from "../reducers/settings-reducer";
import UserContext from "../../../../../../../user-context";
import {
  addValueAction,
  deleteComponentSetting,
  getComponentSettings,
  updateValueAction,
} from "../../../../../../../services/settings-service";
import { useFilters } from "./use-filters";
import { useSorting } from "./use-sorting";
import { useColumns } from "./use-columns";
import { useDataLoading } from "./use-data-loading";
import { useSettingKey } from "./use-setting-key";
import { removeColumnProp } from "../utils/remove-column-prop";

export const useSettings = ({
  id,
  context,
  dataSource,
  objectId,
  queryId,
  initialSettings,
  conditions,
}) => {
  const { userId, contextId, companyId } = useContext(UserContext);
  const { state, setState, setField } = useSettingsReducer(initialSettings);
  const { settingKey } = useSettingKey(context, dataSource, objectId, queryId);

  const getSettings = useCallback(
    async (options) => {
      try {
        const res = await getComponentSettings(options);
        const value = JSON.parse(res.value);
        const columns = (value.columns || []).map(removeColumnProp);
        const id = res.id;
        const key = res.key;
        return {
          id,
          key,
          ...value,
          columns,
          sorting: conditions.disableSorting ? [] : value.sorting,
        };
      } catch (e) {
        return {
          id: null,
          key: null,
          columns: [],
          sorting: [],
          itemsPerPage: 20,
        };
      }
    },
    [conditions.disableSorting],
  );

  const getSettingsRequest = useCallback(
    () =>
      getSettings({
        componentElementId: id,
        userId: contextId || userId,
        companyId,
        key: settingKey,
      }),
    [companyId, contextId, getSettings, id, settingKey, userId],
  );

  const { result, loading } = useDataLoading(
    id,
    getSettingsRequest,
    [settingKey],
    settingKey && !!dataSource?.id && context?.state?.__mounted,
    500,
  );

  useEffect(() => {
    setField("loading", true);
    if (result) {
      setState(result);
      setField("loading", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    setField("loading", loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const settingData = useMemo(
    () => ({
      key: settingKey,
      companyId: parseInt(companyId),
      userId: parseInt(contextId || userId),
      componentElementId: parseInt(id),
    }),
    [companyId, contextId, id, settingKey, userId],
  );

  const switchAction = useCallback(
    async (property) => {
      const value = !state[property];

      setField(property, value);

      const key = `use-settings-update-value-${property}`;
      const payload = {
        ...settingData,
        name: property,
        value,
      };

      await updateValueAction(payload, key)
        .then((res) => {
          setField("id", parseInt(res.id));
        })
        .catch(() => {
          addValueAction(payload, key).catch(() => {});
        });

      return value;
    },
    [setField, settingData, state],
  );

  const switchDisableFilters = useCallback(async () => {
    const res = await switchAction("disableFilters");
    return res;
  }, [switchAction]);

  const switchDisableHeader = useCallback(async () => {
    const res = await switchAction("disableHeader");
    return res;
  }, [switchAction]);

  const switchLockRowHeight = useCallback(async () => {
    const res = await switchAction("lockRowHeight");
    return res;
  }, [switchAction]);

  const switchTreeIsOpen = useCallback(async () => {
    const res = await switchAction("treeIsOpen");
    return res;
  }, [switchAction]);

  const updateAction = useCallback(
    async (property, value) => {
      setField(property, value);

      const key = `use-settings-update-value-${property}`;
      const payload = {
        ...settingData,
        name: property,
        value,
      };

      await updateValueAction(payload, key)
        .then((res) => {
          setField("id", parseInt(res.id));
        })
        .catch(() => {});

      return value;
    },
    [setField, settingData],
  );

  const switchNavPosition = useCallback(async () => {
    const newValue = state.navPosition === "top" ? "bottom" : "top";
    const res = await updateAction("navPosition", newValue);
    return res;
  }, [state.navPosition, updateAction]);

  const switchHeaderWhiteSpace = useCallback(async () => {
    const newValue = state.headerWhiteSpace === "nowrap" ? "wrap" : "nowrap";
    const res = await updateAction("headerWhiteSpace", newValue);
    return res;
  }, [state.headerWhiteSpace, updateAction]);

  const updateItemsPerPage = useCallback(
    async (value) => {
      const res = await updateAction("itemsPerPage", value);
      return res;
    },
    [updateAction],
  );

  const updateTreeWidth = useCallback(
    async (value) => {
      const res = await updateAction("treeWidth", value);
      return res;
    },
    [updateAction],
  );

  const clearFilters = useCallback(async () => {
    const res = await updateAction("filters", []);
    return res;
  }, [updateAction]);

  const deleteSetting = useCallback(async () => {
    setField("filters", []);
    setField("sorting", []);
    await deleteComponentSetting(settingData);
    await getSettingsRequest().then(setState);
  }, [getSettingsRequest, setField, setState, settingData]);

  const { columns, updateManyColumns, updateColumn, updateUserColumns } =
    useColumns(state.columns, settingData, (columns) =>
      setField("columns", columns),
    );

  const { filters, handleFilter } = useFilters(
    state.filters,
    settingData,
    (filters) => setField("filters", filters),
  );

  const { sorting, handleSorting } = useSorting(
    state.sorting,
    settingData,
    (sorting) => setField("sorting", sorting),
  );

  const actions = {
    updateColumn,
    updateManyColumns,
    updateUserColumns,
    handleSorting,
    handleFilter,
    clearFilters,
    updateItemsPerPage,
    updateTreeWidth,
    switchDisableFilters,
    switchDisableHeader,
    switchNavPosition,
    switchLockRowHeight,
    switchHeaderWhiteSpace,
    switchTreeIsOpen,
    deleteSetting,
  };

  return {
    settings: { ...state, columns, filters, sorting },
    actions,
  };
};
